import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import EarlyWarningModal from '../EarlyWarningModal';
import useGetEarlyWarningList from '../../../../hooks/TmsReport/EarlyWarning/Get/useGetEarlyWarningList';
import { WARNING_ICON } from '../../../../constants/Order/Common/path';
import generateDefaultPopUpStatusObject from '../../../../utils/TmsReport/EarlyWarning/Generator/generateDefaultPopUpStatusObject';
import useControlCommonItemOpen from '../../../../hooks/Common/useControlCommonItemOpen';

const HeaderEarlyWarning = ({
  division,
  isModalOpen,
  setModalOpen,
  earlyWarningEditPermission,
}) => {
  const [earlyWarningLength, setEarlyWarningLength] = useState('');

  const { earlyWarningList: earlyWarningData, refetchEarlyWarningList } =
    useGetEarlyWarningList({
      queryObject: generateDefaultPopUpStatusObject({ div: division }),
    });

  useEffect(() => {
    if (earlyWarningData?.data) {
      const length = earlyWarningData?.data?.length;
      setEarlyWarningLength(length < 100 ? length : '99+');
    }
  }, [earlyWarningData]);

  const { buttonRef, modalRef } = useControlCommonItemOpen({
    open: isModalOpen,
    setOpen: setModalOpen,
  });

  return (
    <>
      <div
        ref={buttonRef}
        onClick={() => setModalOpen(!isModalOpen)}
        className={`flex items-center relative cursor-pointer hover:bg-[#556DA1] hover:rounded-md px-3 py-2 ml-10 
        ${isModalOpen ? 'bg-[#556DA1] rounded-md' : ''} 
      `}
      >
        <img src={WARNING_ICON} alt="warningIcon" width="20" height="18" />
        <div className="cursor-pointer">
          <div
            className={`bg-red-600 flex justify-center text-white z-30 rounded-[10px] px-2 h-[14px] absolute text-[13px] top-[0.5em] right-[0em] text-center  font-normal ${
              earlyWarningLength < 100 ? 'w-[21px]' : 'w-[30px]'
            }`}
          >
            <p>{earlyWarningLength}</p>
          </div>
        </div>
      </div>

      <EarlyWarningModal
        ref={modalRef}
        isModalOpen={isModalOpen}
        earlyWarningData={earlyWarningData?.data}
        refetchEarlyWarningList={refetchEarlyWarningList}
        earlyWarningEditPermission={earlyWarningEditPermission}
      />
    </>
  );
};

export default HeaderEarlyWarning;
