import dayjs from 'dayjs';
import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { NULL_PARAMETER_VALUE } from '../../../../constants/Acct/Common/staticNames';
import {
  RECONCILE_STATUS,
  RECONCILE_STATUS_OPTION,
} from '../../../../constants/Acct/PediemReconcile/staticNames';
import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_BUTTON,
  CELL_TYPE_DATE,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import { CELL_CUSTOM_STYLE } from '../../../../styles/Acct/columnStyles';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  FLOATING_FILTER_DEFAULT_OPTION,
  generateConstantOptionToFloatingOption,
  generateListToOption,
} from '../../Common/Generator/floatingFilterSelectOptionGenerator';
import generateInvoiceDateRange from '../../Common/Generator/generateInvoiceDateRange';
import {
  changePairList,
  getPairValue,
} from '../../Common/Handler/changedPairHandler';
import BillButtonCellRender from '../../../../components/Acct/Common/BillButtonCellRender';
import {
  generateDateValue,
  generateSSLValue,
  generateReconcileStatusValue,
} from '../../Common/Generator/dataGridValueGetter';
import generateDateFilters from '../../../Common/Datagrid/Generator/generateDateFilters';

const createPerdiemReconcileColumnDefs = ({
  sslList,
  woOptionList,
  woObjectList,
  setWOQueryObject,
  divList,
  ...columnProps
}) => {
  return [
    {
      headerName: 'DIV',
      field: 'div',
      width: 51,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          disabled: true,
        });
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateListToOption(divList?.map(div => div?.div)),
      },
    },
    {
      headerName: 'VENDOR',
      field: 'vendor',
      width: 72,
      editable: false,
    },
    {
      headerName: 'CONT #',
      field: 'cont_no',
      width: 92,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          onChangeHandler: ({ getChangedValue }) => {
            setWOQueryObject(prev => ({
              ...prev,
              cont_no: getChangedValue || NULL_PARAMETER_VALUE,
            }));
          },
        });
      },
    },
    {
      headerName: 'SSL',
      field: 'ssl',
      width: 55,
      editable: param => param?.data?.editable,
      valueGetter: gridParam => {
        const value =
          generateSSLValue(gridParam?.data?.ssl, sslList) ||
          gridParam?.data?.ssl;
        return value;
      },
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          disabled: true,
        });
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateConstantOptionToFloatingOption(sslList),
      },
    },
    {
      headerName: 'STATUS',
      field: 'status',
      width: 78,
      valueFormatter: gridParam => {
        const value = generateReconcileStatusValue(gridParam?.data?.status);
        return value;
      },
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: RECONCILE_STATUS,
        }),
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: [...FLOATING_FILTER_DEFAULT_OPTION, ...RECONCILE_STATUS_OPTION],
      },
    },
    {
      headerName: 'WO #',
      field: 'wo_no',
      width: 100,
      woOptionList,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_AUTO_COMPLETE,
          option: woOptionList,
          pairList: [
            {
              name: 'bk_no',
              objList: woObjectList,
              findField: 'woBkNo',
              comparisonField: 'woNo',
            },
            {
              name: 'st',
              objList: woObjectList,
              findField: 'woSt',
              comparisonField: 'woNo',
            },
            {
              name: 'div',
              objList: woObjectList,
              findField: 'woDiv',
              comparisonField: 'woNo',
            },
            {
              name: 'from_date',
              objList: woObjectList,
              findField: 'woFromDate',
              comparisonField: 'woNo',
            },
            {
              name: 'to_date',
              objList: woObjectList,
              findField: 'woToDate',
              comparisonField: 'woNo',
            },
            {
              name: 'ssl',
              objList: woObjectList,
              findField: 'woSSL',
              comparisonField: 'woNo',
            },
            {
              name: 'cont_no',
              objList: woObjectList,
              findField: 'woContNo',
              comparisonField: 'woNo',
            },
          ],
          onChangeHandler: ({ ...props }) => {
            changePairList({ ...props });
            const contNo = getPairValue({
              objList: woObjectList,
              comparisonField: 'woNo',
              getChangedValue: props?.getChangedValue,
              findField: 'woContNo',
              ...props,
            });
            setWOQueryObject(prev => ({
              ...prev,
              cont_no: contNo,
            }));
          },
        }),
    },
    {
      headerName: 'MBL / BK #',
      field: 'bk_no',
      width: 160,
      editable: param => param?.data?.editable,
      valueGetter: gridParam => {
        const value = gridParam?.data?.bk_no || gridParam?.data?.mbl_no || '';
        return value;
      },
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          disabled: true,
        });
      },
    },

    {
      headerName: 'S/T',
      field: 'st',
      width: 62,
      cellClass: 'date',
      valueGetter: gridParam => {
        const value = generateDateValue(gridParam?.data?.st);
        return value;
      },
      comparator: dateComparator,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          disabled: true,
        });
      },
      ...generateDateFilters(),
    },
    {
      headerName: 'FROM',
      field: 'from_date',
      width: 65,
      cellClass: 'date',
      valueGetter: gridParam => {
        const value = generateDateValue(gridParam?.data?.from_date);
        return value;
      },
      comparator: dateComparator,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
          disabled: true,
        });
      },
      ...generateDateFilters(),
    },
    {
      headerName: 'TO',
      field: 'to_date',
      width: 62,
      cellClass: 'date',
      valueGetter: gridParam => {
        const value = generateDateValue(gridParam?.data?.to_date);
        return value;
      },
      comparator: dateComparator,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      cellEditorSelector: gridParam => {
        return cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
          disabled: true,
        });
      },
      ...generateDateFilters(),
    },
    {
      headerName: 'NGL USED',
      field: 'ngl_used',
      width: 77,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
        }),
    },
    {
      headerName: 'INV #',
      field: 'inv_no',
      width: 100,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam => cellRendererGenerator({ gridParam }),
    },
    {
      headerName: 'INV DATE',
      field: 'inv_date',
      width: 90,
      cellClass: 'date',
      valueGetter: gridParam => {
        const value = generateDateValue(gridParam?.data?.inv_date);
        return value;
      },
      comparator: dateComparator,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      ...generateDateFilters(),
    },
    {
      headerName: 'FROM',
      field: 'inv_from',
      width: 65,
      cellClass: 'date',
      valueGetter: gridParam => {
        const value = generateDateValue(gridParam?.data?.inv_from);
        return value;
      },
      comparator: dateComparator,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
          onChangeHandler: ({ getChangedValue }) => {
            const invDate =
              getChangedValue && dayjs(getChangedValue)?.isValid()
                ? dayjs(getChangedValue)?.format('YYYY-MM-DD')
                : '';
            setWOQueryObject(prev => ({
              ...prev,
              ...generateInvoiceDateRange(invDate),
            }));
          },
        }),
      ...generateDateFilters(),
    },
    {
      headerName: 'TO',
      field: 'inv_to',
      width: 62,
      cellClass: 'date',
      comparator: dateComparator,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.date,
          },
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      valueGetter: gridParam => {
        const value = generateDateValue(gridParam?.data?.inv_to);
        return value;
      },
      ...generateDateFilters(),
    },
    {
      headerName: 'DAYS',
      field: 'inv_days',
      width: 50,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
        }),
    },
    {
      headerName: 'AMT',
      field: 'inv_amt',
      width: 85,
      editable: param => param?.data?.editable,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
          adornment: {
            position: 'start',
            content: '$',
          },
        }),
    },
    {
      headerName: 'DIFFER',
      field: 'difference',
      width: 60,
      editable: false,
      valueGetter: gridParam => {
        const value = gridParam?.data?.ngl_used - gridParam?.data?.inv_days;
        return value;
      },
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          customStyle: {
            ...CELL_CUSTOM_STYLE.number,
          },
        }),
      cellStyle: params => {
        if (params.value !== 0) {
          return { backgroundColor: '#F0A0A0' };
        }
        return null;
      },
    },
    {
      headerName: 'FILE',
      width: 70,
      field: 'fileButton',
      floatingFilter: false,
      sortable: false,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_BUTTON,
          customStyle: {
            size: 'small',
          },
          content: 'File',
          category: 'invoice',
          onClickHandler: ({ ...props }) => {
            window.open(props?.gridParam?.data?.inv_file);
          },
        }),
      cellEditorSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_BUTTON,
          customStyle: {
            size: 'small',
          },
          content: 'File',
          category: 'invoice',
          onClickHandler: ({ ...props }) => {
            window.open(props?.gridParam?.data?.inv_file);
          },
        }),
    },
    {
      headerName: 'BILL',
      width: 70,
      field: 'editable',
      floatingFilter: false,
      cellRenderer: BillButtonCellRender,
      cellRendererParams: {
        ...columnProps,
      },
      cellEditorSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_BUTTON,
          customStyle: {
            size: 'small',
            ...CELL_CUSTOM_STYLE.button,
          },
          content: 'Bill',
          category: 'invoice',
          disabled: true,
        }),
    },
  ];
};

export default createPerdiemReconcileColumnDefs;
