import dayjs from 'dayjs';
import {
  CELL_TYPE_DATE,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
} from '../../../../constants/Common/DataGrid/cellType';
import { REGEX_NUMBER_MINUS_DECIMAL } from '../../../../constants/Common/regex';
import {
  tierDropdownOptions,
  TIERWIEHT_STATUS,
  TIERWIEHT_STATUS_OBJECT,
  typeDropdownOptions,
} from '../../../../constants/Master/TierWeight/elements';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import convertStandardCodeToText from '../../../Common/convertStandardCodeToText';
import generateDateFilters from '../../../Common/Datagrid/Generator/generateDateFilters';

const createMasterTierWeightColDefs = () => {
  return [
    {
      headerName: 'TIER',
      field: 'tier',
      width: 57,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: tierDropdownOptions,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: tierDropdownOptions,
        }),
    },
    {
      headerName: 'TYPE',
      field: 'type',
      width: 62,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: typeDropdownOptions,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_SELECT,
          option: typeDropdownOptions,
        }),
    },
    {
      headerName: 'RATE',
      field: 'rate',
      width: 85,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          regex: REGEX_NUMBER_MINUS_DECIMAL,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          regex: REGEX_NUMBER_MINUS_DECIMAL,
          customStyle: {
            textAlign: 'right',
          },
          adornment: {
            position: 'start',
            content: '$',
          },
        }),
    },
    {
      headerName: 'UPDATED BY',
      field: 'updated_by',
      editable: false,
      width: 92,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
        }),
    },
    {
      headerName: 'EFFECTIVE DATE',
      field: 'effective_date',
      width: 127,
      cellClass: 'date',
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      cellEditorSelector: gridParam =>
        cellEditorGenerator({
          gridParam,
          cellType: CELL_TYPE_DATE,
        }),
      valueGetter: params => {
        const dateValue = params.data?.effective_date;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      comparator: dateComparator,
      ...generateDateFilters(),
    },
    {
      headerName: 'STATUS',
      field: 'status',
      width: 120,
      editable: false,
      cellRendererSelector: gridParam =>
        cellRendererGenerator({
          gridParam,
        }),
      valueGetter: params => {
        return (
          convertStandardCodeToText(
            params?.data?.status,
            TIERWIEHT_STATUS,
            TIERWIEHT_STATUS_OBJECT,
          ) || params?.data?.status
        );
      },
    },
  ];
};

export default createMasterTierWeightColDefs;
