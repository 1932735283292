import React from 'react';
import CommonToggle from '../../../Inputs/CommonToggle/CommonToggle';

const ToggleWithText = ({
  name,
  inputName,
  isBorder,
  isActive,
  isDisabled,
  div,
  mutation,
  seq,
  width,
}) => {
  return (
    <div
      className={`flex justify-between items-center pl-[16px] pr-[4px] h-[30px] 
      ${width || 'w-full'} 
      ${isBorder ? 'rounded-[5px] border border-zinc-300' : ''}`}
    >
      <div className="text-black text-xs font-medium">{name}</div>
      <div className="flex items-center">
        <CommonToggle
          labelVisible={false}
          isBetween
          disabled={isDisabled}
          options={[
            {
              defaultValue: isActive,
              inputName: `${inputName}`,
            },
          ]}
          onChangeHandler={({ e, onChange, setDisabled, ...handlerProps }) => {
            setDisabled(true);
            onChange(e?.target?.checked);
            mutation?.({ div, type: inputName, seq, setDisabled });
          }}
        />
      </div>
    </div>
  );
};

export default ToggleWithText;
