import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import NoSelectedDocumentPreview from './NoSelectedDocumentPreview';
import { PREVIEW_STATUS } from '../../../../constants/BCDetail/Document';
import handleDocumentChange from '../../../../utils/BCDetail/Document/Handler/handleDocumentChange';
import NoUploadDocumentPreview from './NoUploadDocumentPreview';
import NoPDFDocumentPreview from './NoPDFDocumentPreview';
import AvailablePDFPreview from './AvailablePDFPreview';
import CommonButton from '../../../CommonButton/CommonButton';
import handleCopyClipBoard from '../../../../utils/Common/Handler/handleCopyClipBoard';
import useControlToast from '../../../../hooks/Common/Toast/useControlToast';
import handleCopyButtonDisabled from '../../../../utils/BCDetail/Document/Handler/handleCopyButtonDisabled';

const Preview = ({
  documentList,
  selectedDocType,
  documentController,
  fileVersions,
}) => {
  const { getValues } = documentController;
  const { addToast } = useControlToast();

  const [fileURL, setFileURL] = useState('');
  const [isDisabledCopy, setIsDisabledCopy] = useState(false);

  const watchFileList = getValues('file');

  const [previewStatus, setPreviewStatus] = useState(
    PREVIEW_STATUS?.NO_SELECTED_DOC,
  );

  useEffect(() => {
    const { fileURL: newFileURL, status: newStatus } = handleDocumentChange({
      documentList,
      selectedDocType,
      watchFileList,
      setFileURL,
      fileVersions,
      setIsDisabledCopy,
    });

    setPreviewStatus(newStatus);
    setFileURL(newFileURL);
  }, [documentList, selectedDocType, watchFileList]);

  useEffect(() => {
    handleCopyButtonDisabled({ fileURL, setIsDisabledCopy });
  }, [fileURL]);

  return (
    <div className="flex flex-col gap-y-[9px] max-w-[1000px] h-full flex-1 pt-[7px]">
      <div className="w-full h-fit flex justify-end">
        <CommonButton
          customStyle={{
            width: '108px',
            height: '30px',
            borderColor: '#264B9F',
          }}
          className="border-[#CCD3E3] text-[11px]"
          category="chain"
          type="button"
          disabled={isDisabledCopy}
          onClick={() => {
            const removeVersionURL = fileURL?.split('?')?.[0];
            handleCopyClipBoard({ copyText: removeVersionURL, addToast });
          }}
        >
          COPY
        </CommonButton>
      </div>
      {previewStatus === PREVIEW_STATUS?.NO_SELECTED_DOC && (
        <NoSelectedDocumentPreview />
      )}

      {previewStatus === PREVIEW_STATUS?.NO_UPLOAD_DOC && (
        <NoUploadDocumentPreview />
      )}

      {previewStatus === PREVIEW_STATUS?.NO_PDF_FILE && (
        <NoPDFDocumentPreview fileURL={fileURL} />
      )}

      {previewStatus === PREVIEW_STATUS?.AVAILABLE_PREVIEW && (
        <AvailablePDFPreview fileURL={fileURL} />
      )}
    </div>
  );
};

export default Preview;
