const DOCUMENT_GROUP = [
  {
    headerName: 'POD',
    field: 'doc_pod',
    width: 150,
  },
  {
    headerName: 'B/L',
    field: 'doc_bl',
    width: 100,
  },
  {
    headerName: 'DO',
    field: 'doc_do',
    width: 100,
  },
  {
    headerName: 'IT',
    field: 'doc_it',
    width: 100,
  },
];

const DOCUMENT_GROUP_EXP = [
  {
    headerName: 'POL',
    field: 'doc_pod',
    width: 150,
  },
  {
    headerName: 'B/L',
    field: 'doc_bl',
    width: 100,
  },
  {
    headerName: 'DO',
    field: 'doc_do',
    width: 100,
  },
  {
    headerName: 'IT',
    field: 'doc_it',
    width: 100,
  },
];

const AP_GROUP = [
  { headerName: 'AP', field: 'ap', width: 120 },
  {
    headerName: 'DRV/P',
    field: 'ap_drv',
    width: 120,
  },
  {
    headerName: 'CH/P',
    field: 'ap_ch',
    width: 120,
  },
  {
    headerName: 'PE/P',
    field: 'ap_pe',
    width: 120,
  },
  {
    headerName: 'DE/P',
    field: 'ap_de',
    width: 120,
  },
  {
    headerName: 'DR/P',
    field: 'ap_dr',
    width: 120,
  },
];

const AR_GROUP = [
  {
    headerName: 'AR',
    field: 'ar',
    width: 120,
  },
  {
    headerName: 'TRUCKING',
    field: 'trucking',
    width: 130,
  },
  {
    headerName: 'FSC/R',
    field: 'ar_fsc',
    width: 120,
  },
  {
    headerName: 'CH/R',
    field: 'ar_ch',
    width: 120,
  },
  {
    headerName: 'PE/R',
    field: 'ar_pe',
    width: 120,
  },
  {
    headerName: 'DE/R',
    field: 'ar_de',
    width: 120,
  },
  {
    headerName: 'DR/R',
    field: 'ar_dr',
    width: 120,
  },
];

const PULL_OUT_GROUP = [
  { headerName: 'LOCATION', field: 'po_location', width: 120 },
  {
    headerName: 'SCHEDULE',
    field: 'po_sche_datetime',
  },
  {
    headerName: 'ACTUAL IN',
    field: 'po_actual_in_datetime',
  },
  {
    headerName: 'ACTUAL OUT',
    field: 'po_actual_out_datetime',
  },
  {
    headerName: 'DRIVER',
    field: 'po_driver',
    width: 120,
  },
];

const DELIVERY_GROUP = [
  { headerName: 'LOCATION', field: 'dilv_location', width: 120 },
  { headerName: 'CITY', field: 'dilv_city' },
  { headerName: 'ST', field: 'dilv_state' },
  {
    headerName: 'SCHEDULE',
    field: 'dilv_sche_datetime',
  },
  {
    headerName: 'ACTUAL IN',
    field: 'dilv_actual_in_datetime',
  },
  {
    headerName: 'ACTUAL OUT',
    field: 'dilv_actual_out_datetime',
  },
  {
    headerName: 'DRIVER',
    field: 'dilv_driver',
    width: 120,
  },
];

const PICK_UP_GROUP = [
  { headerName: 'LOCATION', field: 'pu_location', width: 120 },
  {
    headerName: 'SCHEDULE',
    field: 'pu_sche_datetime',
  },
  {
    headerName: 'ACTUAL IN',
    field: 'pu_actual_in_datetime',
  },
  {
    headerName: 'ACTUAL OUT',
    field: 'pu_actual_out_datetime',
  },
  {
    headerName: 'DRIVER',
    field: 'pu_driver',
    width: 120,
  },
];

const RETURN_GROUP = [
  { headerName: 'LOCATION', field: 'rtn_location', width: 120 },
  {
    headerName: 'SCHEDULE',
    field: 'rtn_sche_datetime',
  },
  {
    headerName: 'ACTUAL IN',
    field: 'rtn_actual_in_datetime',
  },
  {
    headerName: 'ACTUAL OUT',
    field: 'rtn_actual_out_datetime',
  },
  {
    headerName: 'DRIVER',
    field: 'rtn_driver',
    width: 120,
  },
];

const GROUP_DEFS = {
  po_g: {
    headerName: 'PULL OUT',
    headerClass: 'blue1',
    children: PULL_OUT_GROUP,
  },
  dilv_g: {
    headerName: 'DELIVERY',
    headerClass: 'blue2',
    children: DELIVERY_GROUP,
  },
  pu_g: {
    headerName: 'PICK UP',
    headerClass: 'blue3',
    children: PICK_UP_GROUP,
  },
  rtn_g: {
    headerName: 'RETURN',
    headerClass: 'blue4',
    children: RETURN_GROUP,
  },
  ar_g: {
    headerName: 'AR',
    headerClass: 'blue5',
    children: AR_GROUP,
  },
  ap_g: {
    headerName: 'AP',
    children: AP_GROUP,
  },
  doc_g: {
    headerName: 'DOCUMENT',
    headerClass: 'blue6',
    children: DOCUMENT_GROUP,
  },
  doc_g_exp: {
    headerName: 'DOCUMENT',
    headerClass: 'blue6',
    children: DOCUMENT_GROUP_EXP,
  },
};

export {
  AP_GROUP,
  AR_GROUP,
  DELIVERY_GROUP,
  DOCUMENT_GROUP,
  DOCUMENT_GROUP_EXP,
  GROUP_DEFS,
  PICK_UP_GROUP,
  PULL_OUT_GROUP,
  RETURN_GROUP,
};
