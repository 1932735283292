import React from 'react';
import StyledDivConfFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfFieldLabel';
import ToggleWithText from '../ToggleWithText';

const AvailabilityContainer = ({ div }) => {
  return (
    <div className="flex flex-col gap-y-[12px]">
      <StyledDivConfFieldLabel>AVAILABILITY FIELD</StyledDivConfFieldLabel>
      <div className="grid grid-cols-2 gap-[12px]">
        <div className="w-full">
          <ToggleWithText
            name="DSC"
            inputName="dsc"
            isBorder
            isActive
            isDisabled
            div={div}
          />
        </div>
        <div className="w-full">
          <ToggleWithText
            name="OBL"
            inputName="obl"
            isBorder
            isActive
            isDisabled
            div={div}
          />
        </div>
        <div className="w-full">
          <ToggleWithText
            name="CUS"
            inputName="cus"
            isBorder
            isActive
            isDisabled
            div={div}
          />
        </div>
        <div className="w-full">
          <ToggleWithText
            name="OTH"
            inputName="oth"
            isBorder
            isActive={false}
            isDisabled
            div={div}
          />
        </div>
        <div className="w-full">
          <ToggleWithText
            name="CA"
            inputName="ca"
            isBorder
            isActive={false}
            isDisabled
            div={div}
          />
        </div>
        <div className="w-full">
          <ToggleWithText
            name="NA"
            inputName="na"
            isBorder
            isActive={false}
            isDisabled
            div={div}
          />
        </div>
        <div className="w-full">
          <ToggleWithText
            name="BO"
            inputName="bo"
            isBorder
            isActive={false}
            isDisabled
            div={div}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailabilityContainer;
