import React from 'react';
import ToggleWithText from '../ToggleWithText';
import StyledDivConfFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfFieldLabel';

const EmailButtonContainer = ({ emailBtn, div, patchEmailButtonMutation }) => {
  return (
    <div className="flex flex-col gap-y-[12px]">
      <StyledDivConfFieldLabel>EMAIL BUTTON</StyledDivConfFieldLabel>
      <div className="grid grid-cols-2 gap-[12px]">
        <ToggleWithText
          name="P/O SCHE"
          inputName="P/OUT"
          isActive={emailBtn[0].active}
          div={div}
          mutation={patchEmailButtonMutation}
          isBorder
        />
        <ToggleWithText
          name="DILV SCHE"
          inputName="D/L"
          isActive={emailBtn[1].active}
          div={div}
          mutation={patchEmailButtonMutation}
          isBorder
        />
        <ToggleWithText
          name="POD/POL"
          inputName="POD/POL"
          isActive={emailBtn[2].active}
          div={div}
          mutation={patchEmailButtonMutation}
          isBorder
        />
        <ToggleWithText
          name="DEMURRAGE"
          inputName="DEMUR"
          isActive={emailBtn[3].active}
          div={div}
          mutation={patchEmailButtonMutation}
          isBorder
        />
        <ToggleWithText
          name="WAITING"
          inputName="WAIT"
          isActive={emailBtn[4].active}
          div={div}
          mutation={patchEmailButtonMutation}
          isBorder
        />
        <ToggleWithText
          name="PERDIEM"
          inputName="PerDiem"
          isActive={emailBtn[5].active}
          div={div}
          mutation={patchEmailButtonMutation}
          isBorder
        />
      </div>
    </div>
  );
};

export default EmailButtonContainer;
