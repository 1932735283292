import MultiFilterHiddenInput from '../../../../components/CommonDataGrid/Component/MultiFilterRenderer/MultiFilterHiddenInput';
import MultiFloatingFilterDatetime from '../../../../components/CommonDataGrid/Component/MultiFilterRenderer/MultiFloatingFilterDatetime';
import generateDatetimeTreePath from './generateDatetimeTreePath';

const generateDatetimeFilters = () => {
  return {
    suppressFloatingFilterButton: false,
    filter: 'agMultiColumnFilter',
    filterParams: {
      filters: [
        {
          filter: MultiFilterHiddenInput,
          floatingFilterComponent: MultiFloatingFilterDatetime,
        },
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            treeList: true,
            excelMode: 'windows',
            closeOnApply: true,
            cellHeight: 24,
            treeListPathGetter: generateDatetimeTreePath,
          },
        },
      ],
    },
  };
};

export default generateDatetimeFilters;
