import { useEffect, useState } from 'react';
import {
  STATISTICS_TABLE_TITLE_VALUE,
  WAREHOUSE_DIVISION,
} from '../../../constants/TmsReport/Statistics/statisticsValues';

const useControlStatisticsList = ({ statisticsList, divQuery }) => {
  const [filteredStatisticsList, setFilteredStatisticsList] = useState({});

  useEffect(() => {
    /** filtered Division List */
    const divList = [
      'ALL',
      ...Object?.keys?.(divQuery)?.filter(div => divQuery?.[div]),
    ];

    const tempFilteredStatList = { ...statisticsList };

    /** Adding Stat Item that is fetched  */
    const existedTitleStat = Object.keys(STATISTICS_TABLE_TITLE_VALUE).reduce(
      (result, key) => {
        if (tempFilteredStatList[key]) {
          result[key] = tempFilteredStatList[key];
        }
        return result;
      },
      {},
    );

    /** Sort in the order of division */
    Object.keys(existedTitleStat).forEach(key => {
      const filteredDivList = key === 'empty-load' ? ['PHX'] : [...divList];

      existedTitleStat[key] = Object?.fromEntries(
        filteredDivList?.map(div => [div, existedTitleStat?.[key]?.[div]]),
      );
    });

    /** Warehouse Field Filtered */
    if (existedTitleStat?.invoice) {
      const newInvoice = { ...existedTitleStat?.invoice };

      Object.keys(newInvoice).forEach(div => {
        if (div !== 'ALL' && !WAREHOUSE_DIVISION?.includes(div)) {
          delete newInvoice[div].warehouse;
        }
      });

      existedTitleStat.invoice = newInvoice;
    }

    setFilteredStatisticsList(existedTitleStat);
  }, [divQuery, statisticsList]);

  return { filteredStatisticsList };
};

export default useControlStatisticsList;
