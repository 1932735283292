import { useMutation, useQueryClient } from '@tanstack/react-query';
import postCompanyData from '../../../services/Master/Company/Post/postCompanyData';
import masterKeys from '../keys';
import handleCompanyData from '../../../utils/Master/Company/Handler/handleCompanyData';
import handleReset from '../../../utils/Master/Common/Handler/handleReset';
import { QB_AUTH_CODE } from '../../../constants/Acct/Common/errors';
import useControlToast from '../../Common/Toast/useControlToast';
import { SUCCESS_CREATE } from '../../../constants/Common/successMessage';
import updateButtonDisabled from '../../../utils/Common/Handler/updateButtonDisabled';

const usePostCompanyData = ({
  companyDetail,
  setMessageContent,
  setMessageModalOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: ({ submitData }) => {
      const newSubmitData = handleCompanyData({
        data: submitData,
        isAdd: true,
      });
      return postCompanyData(newSubmitData);
    },
    refetchOnWindowFocus: false,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: response => {
      queryClient.invalidateQueries({ queryKey: masterKeys.companyList() });
      handleReset(companyDetail);
      addToast({
        message: `Company ID "${response?.data?.company}" ${SUCCESS_CREATE}`,
      });
    },
    onError: error => {
      if (
        error?.cause?.response?.status === 302 &&
        error?.cause?.response?.data?.code === QB_AUTH_CODE
      ) {
        window.open(error?.cause?.response?.data?.url, '_blank');
      } else {
        setMessageContent(error.message);
        setMessageModalOpen(true);
      }
    },
  });
  return mutator;
};

export default usePostCompanyData;
