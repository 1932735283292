import React from 'react';
import HeaderButtons from '../../../components/TmsReport/Schedules/HeaderButtons';
import WeeklyTable from '../../../components/TmsReport/Schedules/WeeklyTable';
import useGetSchedule from '../../../hooks/TmsReport/Schedules/useGetSchedule';
import useControlQueries from '../../../hooks/TmsReport/Schedules/useControlQueries';
import useControlWeeklyData from '../../../hooks/TmsReport/Schedules/useControlWeeklyData';
import useControlFilters from '../../../hooks/TmsReport/Schedules/useControlFilters';

const Delivery = () => {
  const STORAGE_NAME = 'deliveryFilter';

  const { isOpenFilter, setIsOpenFilter, filterState, setFilterState } =
    useControlFilters({ storageName: STORAGE_NAME });

  const { dateQuery, setDateQuery, filterQuery, weeklyDateQuery } =
    useControlQueries({
      filterState,
    });

  const filterProps = {
    isOpenFilter,
    setIsOpenFilter,
    filterState,
    setFilterState,
  };

  const { scheduleData } = useGetSchedule({
    type: 'dilv',
    weeklyDateQuery,
    ...filterQuery,
  });

  const { weeklyData } = useControlWeeklyData({
    weeklyDateQuery,
    scheduleData,
  });

  return (
    <div className="flex flex-col pr-[40px] pb-[20px] h-full w-full">
      <HeaderButtons
        dateQuery={dateQuery}
        setDateQuery={setDateQuery}
        storageName={STORAGE_NAME}
        {...filterProps}
      />
      {Object?.keys?.(weeklyData)?.length > 0 && (
        <WeeklyTable
          pageType="dilv"
          weeklyData={weeklyData}
          filterQuery={filterQuery}
        />
      )}
    </div>
  );
};

export default Delivery;
