export const ERROR_REQUIRED = 'field(s) is required.';
export const ERROR_INVALID = 'field(s) is invalid.';
export const ERROR_UNIQUE = 'field(s) should be given a unique value.';
export const ERROR_PAIR_UNIQUE = 'field(s) pairs must have unique values.';
export const ERROR_NOT_FOUND = `Sorry, we couldn't find what you were looking for.`;
export const ERROR_PERMISSION_DENIED = 'Permission denied.';
export const ERROR_QB_ALREADY_EXIST = 'is already exist in QuickBook.';
export const ERROR_DEPENDENT = 'Please check dependent fields follow.';
export const ERROR_MUTEX = `field(s) can't be used together. Choice one of them.`;
export const ERROR_ASSIGNED = `field(s) already assigned. Please check again.`;
export const ERROR_DUPLICATED = `field(s) is duplicated.`;
export const ERROR_SAME_LOCATION = `Follow location should be different.`;
export const ERROR_DEACTIVATION_FAIL = `make(s) deactivation failed.`;
export const ERROR_INACTIVE_USER = `Your account has been deactivated. Please contact to System Admin.`;
export const ERROR_BILLTO_CREDIT_HOLD = `This Bill To is under Credit Hold.`;
export const ERROR_INVALID_9_2_DECIMAL = `Please enter a decimal number with up to 9 digits and 2 decimal places.`;
export const ERROR_NOT_AUTHOR = "is not your account. You can't edit this.";
export const ERROR_MAIN_PERMISSION_MISSING = 'Main Page permission is required';
export const ERROR_PERMISSION_MISSING_FOR_FIRST_PAGE =
  "Please change user's default page to update MAIN.";
export const ERROR_ZIP_CODE_TIMEZONE_INVALID =
  'Cannot find timezone with the given zip code.';
export const ERROR_VALUE_EXISTS =
  'This BK# is currently in use. Please complete all WOs and try again.';
export const ERROR_WO_NOT_FOUND = 'WO could not be found.';
export const ERROR_COMPLETE_WO_DELETE = 'Complete WO could not be deleted.';
export const ERROR_EXISTING_AP_AR_DELETE =
  'WO could not be deleted due to existing AP/AR.';
export const ERROR_EDI_204_UPDATE =
  'Unable to update due to existing DAT status in EDI 214.';
export const ERROR_GROUP_HAS_USER = 'User is assigned. Please try again.';
export const ERROR_PERMISSION_FIRST_PAGE_MISMATCH = 'Do not match.';
export const ERROR_NOT_SUPPORTED_FILE_SIZE =
  'File size exceeds the maximum limit(2MB).';
export const ERROR_QB_AUTH_CODE = `You've been logged out from QuickBooks. Please log back in.`;
export const ERROR_QB_TIMEOUT =
  'QuickBooks server is currently busy. Try again later.';
export const ERROR_QB_DELETED = 'WO # has been deleted.';
export const ERROR_JOB_NOT_COMPLETED = 'Please finish the job first.';
export const ERROR_DIVISION_HAS_WO =
  'WO rule cannot be modified due to existing WO.';
export const ERROR_NAVIGATION_FAILED =
  'Failed to load navigation. Please check a mobile app.';
export const ERROR_QB_LOCKED = `You don't have the auth to update. Please contact to admin.`;
export const ERROR_ADDRESS_INVALID =
  'Invalid address. Ensure Address_01, City, State, Zip, and Country are entered correctly.';
export const ERROR_INVALID_LOCATION =
  'Invalid location set. Please check the location and try again.';
export const ERROR_QB_NON_ZERO_BALANCE =
  'The entity you are trying to delete has an open balance.';
export const ERROR_DUAL_JOB_REASSIGN =
  'Cannot reassign driver to the dual job.';
export const ERROR_CONVERTED_EXPORT_WO_DELETE = `Cannot delete converted Export WO. Please use "Import" button instead.`;
export const ERROR_RETURN_LOCATION_NOT_FOUND =
  'Please add a location before creating the schedule.';
export const ERROR_RETURN_SCHEDULE_UPDATE_NOT_ALLOWED =
  'Unable to change schedule date time for in-progress jobs.';
export const ERROR_ROUTE_NOT_SEARCHABLE =
  'Route not found. Please check if the addresses for each location are correct.';
export const ERROR_GET_VEHICLE_FAILED = 'Failed to load vehicle information.';
export const ERROR_CANNOT_DELETE_LOCATION =
  'Cannot delete location because the job has started.';
export const ERROR_NOT_AVAILABLE =
  'Please check all the Availabilities and update LFD.';
export const ERROR_PREVIOUS_ACTUAL_ERROR =
  'Please update Actual In / Out for previous job.';
export const ERROR_DATA_CONFLICTED =
  'Data has been updated. Please try again after the page refreshes.';

export const ERROR_UNKNOWN =
  'Unknown Error occurred :<. Please contact to admin.';
