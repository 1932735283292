import getDateDef from '../../../utils/DailyReport/Common/Generator/getDateDef';
import getDatetimeDef from '../../../utils/DailyReport/Common/Generator/getDatetimeDef';
import getNumberDef from '../../../utils/DailyReport/Common/Generator/getNumberDef';
import CHECKBOX_DEF from '../../Dispatch/Common/checkBoxDef';

const DAILY_REPORT_BASIC_COLUMN_DEF = {
  category: {
    valueGetter: params => {
      if (['WC0001', 'WC0002'].includes(params?.data?.category)) return 'IMP';
      if (['WC0003', 'WC0004'].includes(params?.data?.category)) return 'EXP';
      if (['WC0005'].includes(params?.data?.category)) return 'VAN';
      if (['WC0006'].includes(params?.data?.category)) return 'BROKERAGE';
      if (['WC0007'].includes(params?.data?.category)) return 'BARE';
      return '';
    },
  },

  equipment: {
    valueGetter: params => {
      const category = params?.data?.category;
      if (['WC0001', 'WC0002', 'WC0003', 'WC0004'].includes(category)) {
        return params?.data?.cont_no;
      }
      if (['WC0005', 'WC0006'].includes(category)) {
        return params?.data?.trailer_no;
      }
      return '';
    },
  },

  bk_no: {
    valueGetter: params => {
      const isReturnYard = document?.URL?.includes('return-yard');
      if (params?.data?.category === 'WC0001' && isReturnYard) return '';
      return params?.data?.bk_no;
    },
  },

  mbl_no: {
    valueGetter: params => {
      const isReturnYard = document.URL.includes('return-yard');
      if (['WC0001', 'WC0002'].includes(params?.data?.category) && isReturnYard)
        return '';
      if (['WC0003', 'WC0004'].includes(params?.data?.category))
        return params?.data?.bk_no;
      return params?.data?.mbl_no;
    },
  },

  hazmat: CHECKBOX_DEF,
  wgt: getNumberDef('wgt'),

  lfd: getDateDef('lfd'),
  rfd: getDateDef('rfd'),
  erd: getDateDef('erd'),
  empty_load_cfm: getDateDef('empty_load_cfm'),

  cutoff: getDatetimeDef('cutoff'),
  po_actual_out: getDatetimeDef('po_actual_out'),

  po_schedule: getDatetimeDef('po_schedule'),
  dilv_schedule: getDatetimeDef('dilv_schedule'),
  rtn_schedule: getDatetimeDef('rtn_schedule'),
};

export default DAILY_REPORT_BASIC_COLUMN_DEF;
