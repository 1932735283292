import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonMessageModal from '../../../components/CommonMessageModal/CommonMessageModal';
import AdvancedSearchContent from '../../../components/MobileAdmin/Manifest/AdvancedSearchModal/AdvancedSearchContent';
import AdvancedSearchButtonContent from '../../../components/MobileAdmin/Manifest/AdvancedSearchModal/AdvancedSearchButtonContent';
import HeaderButtons from '../../../components/MobileAdmin/Manifest/HeaderButtons';
import ManifestDetails from '../../../components/MobileAdmin/Manifest/ManifestDetails';
import useGetManifest from '../../../hooks/MobileAdmin/Manifest/useGetManifest';
import defaultColumnDefsGenerator from '../../../utils/Common/Datagrid/Generator/defaultColumnDefsGenerator';
import createManifestColumnDefs from '../../../utils/MobileAdmin/Manifest/Generator/createManifestColumnDefs';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import mobileAdminKeys from '../../../hooks/MobileAdmin/keys';

const Manifest = () => {
  const queryClient = useQueryClient();
  const gridRef = useRef(null);
  const defaultColumnDefs = defaultColumnDefsGenerator();

  const division = useSelector(state => state.nowDiv);

  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [queryObject, setQueryObject] = useState({});

  const AdvancedSearchController = useForm();

  useEffect(() => {
    setQueryObject({});
    AdvancedSearchController.reset();
  }, [division]);

  const {
    data: manifestData,
    isLoading,
    isError,
  } = useGetManifest({
    division,
    queryObject,
  });

  const handleSearchSubmit = submitData => {
    setQueryObject(submitData);
    AdvancedSearchController.reset();
    queryClient.invalidateQueries({
      queryKey: mobileAdminKeys.manifest({ division }),
    });
  };

  const [columnDefs, setColumnDefs] = useState(createManifestColumnDefs());

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: manifestData?.data,
  });

  return (
    <>
      {(isLoading || isError) && <CommonLoading open={isLoading || isError} />}
      <div className="pr-[40px] h-full flex flex-col">
        <HeaderButtons
          setAdvancedSearchOpen={setAdvancedSearchOpen}
          gridRef={gridRef}
          fileName="MANIFEST"
        />
        <div className="w-full flex-1">
          <CommonDataGrid
            gridRef={gridRef}
            data={manifestData?.data}
            columnDefs={columnDefs}
            defaultColDef={defaultColumnDefs}
            foldable
            foldableContent={ManifestDetails}
            onFilterChange={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
        </div>
        <TotalBottomBar totalRows={totalRows} />
      </div>
      <FormProvider {...AdvancedSearchController}>
        <form
          onSubmit={AdvancedSearchController.handleSubmit(handleSearchSubmit)}
        >
          <CommonMessageModal
            open={advancedSearchOpen}
            setOpen={setAdvancedSearchOpen}
            header="Advanced Search"
            content={<AdvancedSearchContent queryObject={queryObject} />}
            buttonContent={
              <AdvancedSearchButtonContent
                setAdvancedSearchOpen={setAdvancedSearchOpen}
              />
            }
          />
        </form>
      </FormProvider>
    </>
  );
};

export default Manifest;
