import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import APContainer from '../../../components/Admin/DivConf/APContainer';
import APTableContainer from '../../../components/Admin/DivConf/APTableContainer';
import APTableRateContainer from '../../../components/Admin/DivConf/APTableRateContainer';
import ARContainer from '../../../components/Admin/DivConf/ARContainer';
import BGColorContainer from '../../../components/Admin/DivConf/BGColorContainer';
import EmailButtonContainer from '../../../components/Admin/DivConf/EmailButtonContainer';
import FunctionsContainer from '../../../components/Admin/DivConf/FunctionsContainer';
import MobileContainer from '../../../components/Admin/DivConf/MobileContainer';
import TimeZoneContainer from '../../../components/Admin/DivConf/TimeZoneContainer';
import CommonDataGrid from '../../../components/CommonDataGrid/index';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import { MAPPING_PAGE } from '../../../constants/Admin/DivConf/adminDivConfElements';
import { GRID_MOD_BOTH } from '../../../constants/Common/DataGrid/setting';
import useDeleteProductCodeMutation from '../../../hooks/Admin/DivConf/Delete/useDeleteProductCodeMutation';
import useDeleteWeightPerZipMutation from '../../../hooks/Admin/DivConf/Delete/useDeleteWeightPerZipMutation';
import useGetAPProductCode from '../../../hooks/Admin/DivConf/Get/useGetAPProductCode';
import useGetARProductCode from '../../../hooks/Admin/DivConf/Get/useGetARProductCode';
import useGetDivInfo from '../../../hooks/Admin/DivConf/Get/useGetDivInfo';
import useGetProductCodeList from '../../../hooks/Admin/DivConf/Get/useGetProductCodeList';
import useGetTimeZoneList from '../../../hooks/Admin/DivConf/Get/useGetTimeZoneList';
import useGetWeightPerZIP from '../../../hooks/Admin/DivConf/Get/useGetWeightPerZIP';
import usePatchAPTableActivateMutation from '../../../hooks/Admin/DivConf/Patch/usePatchAPTableActivateMutation';
import usePatchAPTableRateMutation from '../../../hooks/Admin/DivConf/Patch/usePatchAPTableRateMutation';
import usePatchBGColorActivateMutation from '../../../hooks/Admin/DivConf/Patch/usePatchBGColorActivateMutation';
import usePatchBGColorLBSMutation from '../../../hooks/Admin/DivConf/Patch/usePatchBGColorLBSMutation';
import usePatchBGColorMutation from '../../../hooks/Admin/DivConf/Patch/usePatchBGColorMutation';
import usePatchEmailButtonMutation from '../../../hooks/Admin/DivConf/Patch/usePatchEmailButtonMutation';
import usePatchFunctionsMutation from '../../../hooks/Admin/DivConf/Patch/usePatchFunctionsMutation';
import usePatchProductCodeMutation from '../../../hooks/Admin/DivConf/Patch/usePatchProductCodeMutation';
import usePatchTimeZoneMutation from '../../../hooks/Admin/DivConf/Patch/usePatchTimeZoneMutation';
import usePatchWeightPerZipMutation from '../../../hooks/Admin/DivConf/Patch/usePatchWeightPerZipMutation';
import usePostProductCodeMutation from '../../../hooks/Admin/DivConf/Post/usePostProductCodeMutation';
import usePostWeightPerZipMutation from '../../../hooks/Admin/DivConf/Post/usePostWeightPerZipMutation';
import createSettingsColumnDefs from '../../../utils/Admin/DivConf/Generator/createSettingsColumnDefs';
import handleAddClicked from '../../../utils/Admin/DivConf/handleAddClicked';
import handleDeleteClicked from '../../../utils/Admin/DivConf/handleDeleteClicked';
import handleSaveClicked from '../../../utils/Admin/DivConf/handleSaveClicked';
import AccordionBox from '../../../components/Admin/DivConf/AccordionBox';
import AvailabilityContainer from '../../../components/Admin/DivConf/AvailabilityContainer';
import StyledDivConfServiceCodeTitle from '../../../styles/Admin/DivConf/StyledDivConfServiceCodeTitle';

const DivConf = () => {
  const gridRef = useRef(null);
  const [columnDefs, setColumnDefs] = useState(createSettingsColumnDefs());

  const divConfController = useForm();
  const nowDiv = useSelector(state => state.nowDiv);
  const [divInfo, setDivInfo] = useState(null);
  const [weightPerZIP, setWeightPerZIP] = useState([]);
  const [timeZoneOption, setTimeZoneOption] = useState([]);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [apProductCodeOption, setAPProductCodeOption] = useState([]);
  const [arProductCodeOption, setARProductCodeOption] = useState([]);
  const [apRate, setAPRate] = useState({});
  const [apTableRate, setAPTableRate] = useState({});
  const [truckingCharge, setTruckingCharge] = useState({});
  const [fuelSurcharge, setFuelSurcharge] = useState({});
  const [chassisRate, setChassisRate] = useState({});
  const [dryRun, setDryRun] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [other, setOther] = useState(false);

  const { isLoadingDivInfo, data } = useGetDivInfo({
    div: nowDiv,
  });

  const { isLoadingTimeZoneList, data: timeZoneList } = useGetTimeZoneList();

  const { isLoadingWeightPerZIP, data: weightPerZIPData } = useGetWeightPerZIP({
    div: nowDiv,
  });

  const { isLoadingProductCodeList, data: productCodeList } =
    useGetProductCodeList({ div: nowDiv });

  const { isLoadingAPProductCode, data: apProductCode } = useGetAPProductCode();

  const { isLoadingARProductCode, data: arProductCode } = useGetARProductCode();

  const { mutate: patchTimeZoneMutation } = usePatchTimeZoneMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchBGColorActivateMutation } =
    usePatchBGColorActivateMutation({
      setIsMessageOpen,
      setMessage,
      setIsSuccess,
      div: nowDiv,
    });

  const { mutate: patchBGColorMutation } = usePatchBGColorMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchBGColorLBSMutation } = usePatchBGColorLBSMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchEmailButtonMutation } = usePatchEmailButtonMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchFunctionsMutation } = usePatchFunctionsMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchAPTAbleActivateMutation } =
    usePatchAPTableActivateMutation({
      setIsMessageOpen,
      setMessage,
      setIsSuccess,
      div: nowDiv,
    });

  const { mutate: patchAPTAbleRateMutation } = usePatchAPTableRateMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: postWeightPerZIPMutation } = usePostWeightPerZipMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
    gridRef,
  });

  const { mutate: patchWeightPerZIPMutation } = usePatchWeightPerZipMutation({
    gridRef,
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: deleteWeightPerZIPMutation } = useDeleteWeightPerZipMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: postProductCodeMutation } = usePostProductCodeMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: patchProductCodeMutation } = usePatchProductCodeMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  const { mutate: deleteProductCodeMutation } = useDeleteProductCodeMutation({
    setIsMessageOpen,
    setMessage,
    setIsSuccess,
    div: nowDiv,
  });

  useEffect(() => {
    if (data) {
      setDivInfo(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (weightPerZIPData) {
      weightPerZIPData.forEach(item => {
        item.weight = item.weight.toString();
      });
      setWeightPerZIP(weightPerZIPData);
    }
  }, [weightPerZIPData]);

  useEffect(() => {
    if (timeZoneList) {
      setTimeZoneOption(
        timeZoneList.map(item => ({
          label: item.combine,
          value: item.combine,
        })),
      );
    }
  }, [timeZoneList]);

  useEffect(() => {
    if (productCodeList) {
      setAPRate(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.AP_RATE,
        ) || {},
      );
      setAPTableRate(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.DIV_AP_TABLE,
        ) || {},
      );
      setTruckingCharge(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.TRUCKING_AR_RATE,
        ) || {},
      );
      setFuelSurcharge(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.FUEL_AR_RATE,
        ) || {},
      );
      setChassisRate(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.CHASSIS_RATE,
        ) || {},
      );
      setDryRun(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.DRY_RUN,
        ),
      );
      setWaiting(
        productCodeList.find(
          item => item.mapping_page === MAPPING_PAGE.WAITING,
        ),
      );
      setOther(
        productCodeList.find(item => item.mapping_page === MAPPING_PAGE.OTHERS),
      );
    }
  }, [productCodeList]);

  useEffect(() => {
    if (apProductCode) {
      setAPProductCodeOption(
        apProductCode.map(item => ({
          label: `${item.product_code} / ${item.product_code_name}`,
          value: item.seq.toString(),
          name: item.product_code_name,
        })),
      );
    }
  }, [apProductCode]);

  useEffect(() => {
    if (arProductCode) {
      setARProductCodeOption(
        arProductCode.map(item => ({
          label: `${item.product_code} / ${item.product_code_name}`,
          value: item.seq.toString(),
          name: item.product_code_name,
        })),
      );
    }
  }, [arProductCode]);

  return (
    <>
      <CommonLoading
        open={
          isLoadingDivInfo ||
          isLoadingTimeZoneList ||
          isLoadingWeightPerZIP ||
          isLoadingProductCodeList
        }
      />
      {!isSuccess && (
        <CommonErrorModal
          open={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          message={message}
          isSuccess={isSuccess}
        />
      )}
      {divInfo && weightPerZIP && (
        <FormProvider {...divConfController}>
          <form>
            <div className="flex py-[30px] gap-x-[32px]">
              <div className="flex flex-col gap-y-[32px]">
                <div className="flex gap-x-[32px]">
                  {/* TIMEZONE & BG COLOR */}
                  <AccordionBox
                    title="TIMEZONE & BACKGROUND COLOR "
                    content={
                      <div className="flex flex-col gap-y-[24px]">
                        <TimeZoneContainer
                          timeZoneOption={timeZoneOption}
                          timeZone={divInfo.timezone}
                          div={nowDiv}
                          patchTimeZoneMutation={patchTimeZoneMutation}
                        />
                        <BGColorContainer
                          bgColor={divInfo.bg_color}
                          div={nowDiv}
                          patchBGColorActivateMutation={
                            patchBGColorActivateMutation
                          }
                          patchBGColorMutation={patchBGColorMutation}
                          patchBGColorLBSMutation={patchBGColorLBSMutation}
                          setIsMessageOpen={setIsMessageOpen}
                          setMessage={setMessage}
                          setIsSuccess={setIsSuccess}
                        />
                      </div>
                    }
                  />

                  {/* EMAIL BUTTON & FUNC */}
                  <AccordionBox
                    title="EMAIL BUTTON & FUNCTIONS"
                    content={
                      <div className="flex flex-col gap-y-[24px]">
                        <EmailButtonContainer
                          emailBtn={divInfo.email_btn}
                          div={nowDiv}
                          patchEmailButtonMutation={patchEmailButtonMutation}
                        />
                        <FunctionsContainer
                          isActive={divInfo.tracking_func}
                          div={nowDiv}
                          patchFunctionsMutation={patchFunctionsMutation}
                        />
                      </div>
                    }
                  />
                </div>

                {/* Second Row */}
                <div className="flex gap-x-[32px]">
                  <AccordionBox
                    title="AVAILABILITY"
                    content={
                      <div className="flex flex-col gap-y-[24px]">
                        <AvailabilityContainer div={nowDiv} />
                      </div>
                    }
                  />
                  {/* SERVICE CODE */}
                  <AccordionBox
                    title="SERVICE CODE "
                    content={
                      <div className="flex flex-col gap-y-[24px]">
                        <APContainer
                          apRate={apRate}
                          apProductCodeOption={apProductCodeOption}
                          div={nowDiv}
                          postProductCodeMutation={postProductCodeMutation}
                          patchProductCodeMutation={patchProductCodeMutation}
                          deleteProductCodeMutation={deleteProductCodeMutation}
                          isLoadingAPProductCode={isLoadingAPProductCode}
                        />
                        <ARContainer
                          truckingCharge={truckingCharge}
                          fuelSurcharge={fuelSurcharge}
                          chassisRate={chassisRate}
                          arProductCodeOption={arProductCodeOption}
                          div={nowDiv}
                          postProductCodeMutation={postProductCodeMutation}
                          patchProductCodeMutation={patchProductCodeMutation}
                          deleteProductCodeMutation={deleteProductCodeMutation}
                          isLoadingARProductCode={isLoadingARProductCode}
                        />
                        <MobileContainer
                          dryRun={dryRun}
                          waiting={waiting}
                          other={other}
                          apProductCodeOption={apProductCodeOption}
                          div={nowDiv}
                          postProductCodeMutation={postProductCodeMutation}
                          patchProductCodeMutation={patchProductCodeMutation}
                          deleteProductCodeMutation={deleteProductCodeMutation}
                          isLoadingAPProductCode={isLoadingAPProductCode}
                        />
                      </div>
                    }
                  />
                </div>
                {/* Third Rows */}
                <div className="flex gap-x-[32px]">
                  <AccordionBox
                    width="w-[1512px]"
                    title="AP TABLE"
                    content={
                      <div className="flex flex-col h-full">
                        <div className="w-full h-full flex gap-x-[36px]">
                          <APTableContainer
                            apTable={divInfo.ap_table}
                            div={nowDiv}
                            patchAPTAbleActivateMutation={
                              patchAPTAbleActivateMutation
                            }
                          />
                          <div className="w-[456px]">
                            <APTableRateContainer
                              apTableRate={apTableRate}
                              apTable={divInfo.ap_table}
                              apProductCodeOption={apProductCodeOption}
                              div={nowDiv}
                              postProductCodeMutation={postProductCodeMutation}
                              patchProductCodeMutation={
                                patchProductCodeMutation
                              }
                              deleteProductCodeMutation={
                                deleteProductCodeMutation
                              }
                              patchAPTAbleRateMutation={
                                patchAPTAbleRateMutation
                              }
                              isLoadingAPProductCode={isLoadingAPProductCode}
                            />
                          </div>
                          <div className="h-full w-[676px] flex flex-col gap-y-[8px]">
                            <StyledDivConfServiceCodeTitle>
                              WEIGHT FOR ZIP CODE (TARGET)
                            </StyledDivConfServiceCodeTitle>
                            <CommonDataGrid
                              gridRef={gridRef}
                              addable
                              modType={GRID_MOD_BOTH}
                              data={weightPerZIP}
                              columnDefs={columnDefs}
                              onAddClicked={e =>
                                handleAddClicked({
                                  e,
                                  div: nowDiv,
                                  postWeightPerZIPMutation,
                                })
                              }
                              onSaveClicked={e =>
                                handleSaveClicked({
                                  e,
                                  patchWeightPerZIPMutation,
                                })
                              }
                              onDeleteClicked={e =>
                                handleDeleteClicked({
                                  e,
                                  deleteWeightPerZIPMutation,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default DivConf;
