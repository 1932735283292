import { useMutation } from '@tanstack/react-query';
import { logout } from '../../services/SignIn/Get/logout';
import clearLocalStorage from '../../utils/Common/Handler/clearLocalStorage';

const useLogoutMutation = ({ setIsMessageOpen, setMessage, isLogout }) =>
  useMutation({
    mutationFn: logout,
    onSuccess: res => {
      if (isLogout) {
        clearLocalStorage();
        sessionStorage.clear();
        window.location.href = `/sign-in`;
      }
    },
    onError: error => {
      setMessage(
        error.message === 'undefined <' ? 'Logout Failed' : error.message,
      );
      setIsMessageOpen(true);
    },
  });

export default useLogoutMutation;
