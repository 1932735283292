import { useMutation } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../constants/Common/successMessage';
import patchMain from '../../services/CustomerReport/Patch/patchMain';
import updateButtonDisabled from '../../utils/Common/Handler/updateButtonDisabled';
import useControlToast from '../Common/Toast/useControlToast';

const usePatchMain = ({ setIsSuccess, setModalMessage }) => {
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchMain,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      addToast({ message: SUCCESS_SAVE });
    },
    onError: error => {
      setIsSuccess(false);
      setModalMessage(error?.message);
    },
  });

  return { ...mutator };
};

export default usePatchMain;
