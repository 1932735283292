export const generateTimestamp = () => {
  const timeStamp = Math.floor(Date.now() / 1000);
  return timeStamp;
};

export const generatePreviewURL = ({ timeStamp, fileURL }) => {
  let newFileURL = '';
  if (fileURL) {
    newFileURL = `${fileURL}${timeStamp && `?t=${timeStamp}`}`;
  }
  return newFileURL;
};

export default generateTimestamp;
