import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import CommonDataGrid from '../../../CommonDataGrid';
import { GRID_MOD_EDIT } from '../../../../constants/Common/DataGrid/setting';
import usePostInvoiceAP from '../../../../hooks/Acct/PerdiemReconcile/Post/usePostInvoiceAP';
import createPerdiemReconcileColumnDefs from '../../../../utils/Acct/PerdiemReconcile/Generator/createPerdiemReconcileColumnDefs';
import usePatchStatus from '../../../../hooks/Acct/PerdiemReconcile/Patch/usePatchStatus';
import handleSaveClick from '../../../../utils/Acct/PerdiemReconcile/Handler/handleSaveClick';
import useGetWOList from '../../../../hooks/Acct/PerdiemReconcile/Get/useGetWOList';
import usePatchPerdiemInvoice from '../../../../hooks/Acct/PerdiemReconcile/Patch/usePatchPerdiemReconcile';
import handleCellClick from '../../../../utils/Acct/PerdiemReconcile/Handler/handleCellClick';
import changeDataGridEditSelectOption from '../../../../utils/Acct/Common/Handler/changeDataGridEditSelectOption';
import { NULL_PARAMETER_VALUE } from '../../../../constants/Acct/Common/staticNames';
import { handleDataGridWOOptionChange } from '../../../../utils/Acct/Common/Handler/handleWOOptionChange';
import { generateSSLOption } from '../../../../utils/Acct/PerdiemReconcile/Generator/optionListGenerator';
import useGetUserDivisionList from '../../../../hooks/Acct/Common/Get/useGetUserDivisonList';
import generateInvoiceDateRange from '../../../../utils/Acct/Common/Generator/generateInvoiceDateRange';
import useControlTotalInfo from '../../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import TotalBottomBar from '../../../Common/CommonTotalBottomBar';
import { generateSSLValue } from '../../../../utils/Acct/Common/Generator/dataGridValueGetter';

const DataGridArea = ({
  queryObject,
  perdiemReconcileList,
  refetchPerdiemReconcileList,
  productStatus,
  gridRef,
  setErrorModalOptions,
}) => {
  const { setErrorModalOpen, setErrorMessage, setIsSuccess } =
    setErrorModalOptions;

  const divList = useGetUserDivisionList();
  const originSSLList = useSelector(state => state?.common?.ssl);

  const [sslList, SetSSLList] = useState(false);

  useEffect(() => {
    const tempSSLList = generateSSLOption(originSSLList);
    SetSSLList(tempSSLList);
  }, [originSSLList]);

  const { handleSubmit } = useFormContext();

  const [list, setList] = useState([]);
  const [dataGridDefs, setDataGridDefs] = useState([]);

  const [woQueryObject, setWOQueryObject] = useState({});

  const [isStartEdit, setIsStartEdit] = useState(false);

  const { woOptionList, woObjectList } = useGetWOList({
    queryObject: woQueryObject,
    sslList,
  });

  const { mutate: invoiceAPMutator } = usePostInvoiceAP({
    gridRef,
    setErrorModalOptions,
  });

  const { mutate: statusPatchMutator } = usePatchStatus({
    gridRef,
    setErrorModalOptions,
    queryObject,
  });

  const { mutate: perdiemPatchMutator } = usePatchPerdiemInvoice({
    gridRef,
    setErrorModalOptions,
    queryObject,
  });

  const handleClickInvoice = ({ rowData }) => {
    if (productStatus === 'save') {
      handleSubmit(data => {
        const postData = {
          seq: rowData?.seq,
          product_code: data?.product_code?.value,
        };

        invoiceAPMutator({ postData });
        refetchPerdiemReconcileList();
      })();
    } else {
      setIsSuccess(false);
      setErrorModalOpen(true);
      setErrorMessage('Please check the service code.');
    }
  };

  useEffect(() => {
    if (divList?.length > 0 && sslList?.length > 0) {
      setDataGridDefs(
        createPerdiemReconcileColumnDefs({
          gridRef,
          handleClickInvoice,
          sslList,
          woOptionList,
          woObjectList,
          setWOQueryObject,
          divList,
        }),
      );
    }
  }, [divList, sslList]);

  useEffect(() => {
    if (perdiemReconcileList) {
      const tempList = perdiemReconcileList?.map(item => ({
        ...item,
        ssl: generateSSLValue(item?.ssl, sslList),
        sslList,
      }));
      setList(tempList);
    }
  }, [perdiemReconcileList, sslList]);

  useEffect(() => {
    setDataGridDefs(
      createPerdiemReconcileColumnDefs({
        gridRef,
        handleClickInvoice,
        sslList,
        woOptionList,
        woObjectList,
        setWOQueryObject,
        divList,
      }),
    );
  }, [list, woObjectList, productStatus]);

  useEffect(() => {
    changeDataGridEditSelectOption({
      gridRef,
      editColName: 'wo_no',
      changeOptionList: woOptionList,
    });

    handleDataGridWOOptionChange({
      gridRef,
      woFieldName: 'wo_no',
      woOptionList,
      pairFieldList: ['bk_no', 'st', 'div', 'from_date', 'to_date', 'ssl'],
    });
  }, [woOptionList]);

  useEffect(() => {
    if (isStartEdit) {
      changeDataGridEditSelectOption({
        gridRef,
        editColName: 'wo_no',
        changeOptionList: woOptionList,
      });

      setIsStartEdit(prev => false);
    }
  }, [isStartEdit]);

  const { totalRows, totalAmount, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: list,
    amountField: 'inv_amt',
  });

  return (
    <>
      <CommonDataGrid
        gridRef={gridRef}
        columnDefs={dataGridDefs}
        data={list}
        modType={GRID_MOD_EDIT}
        onSaveClicked={props =>
          handleSaveClick({
            props,
            statusPatchMutator,
            perdiemPatchMutator,
            setErrorModalOptions,
          })
        }
        onCellClicked={handleCellClick}
        onFilterChange={() => {
          setChangeFlag(prev => !prev);
        }}
        onSelectionChanged={() => {
          setChangeFlag(prev => !prev);
        }}
        onRowEditingStart={({ gridParam }) => {
          const invDate =
            gridParam?.data?.inv_from &&
            dayjs(gridParam?.data?.inv_from)?.isValid()
              ? dayjs(gridParam?.data?.inv_from)?.format('YYYY-MM-DD')
              : '';
          setWOQueryObject({
            ...generateInvoiceDateRange(invDate),
            cont_no: gridParam?.data?.cont_no || NULL_PARAMETER_VALUE,
          });
          setIsStartEdit(prev => true);
        }}
      />
      <TotalBottomBar totalRows={totalRows} totalAmount={totalAmount} />
    </>
  );
};

export default DataGridArea;
