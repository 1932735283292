import React from 'react';
import ToggleWithText from '../ToggleWithText';
import StyledDivConfFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfFieldLabel';

const FunctionsContainer = ({ isActive, div, patchFunctionsMutation }) => {
  return (
    <div className="flex flex-col gap-y-[12px]">
      <StyledDivConfFieldLabel>FUNCTIONS</StyledDivConfFieldLabel>
      <div className="w-[332px]">
        <ToggleWithText
          name="Tracking ID"
          inputName="trackingID"
          isActive={isActive}
          div={div}
          mutation={patchFunctionsMutation}
          isBorder
        />
      </div>
    </div>
  );
};

export default FunctionsContainer;
