import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

const generateDateTreePath = date => {
  dayjs.extend(advancedFormat);
  if (dayjs(date, 'MM/DD/YY')?.isValid()) {
    const dateList = dayjs(date, 'MM/DD/YY').format('YYYY-MMMM-Do')?.split('-');
    return dateList;
  }
  return [null];
};

export default generateDateTreePath;
