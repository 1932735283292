import React from 'react';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import StyledDivConfFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfFieldLabel';

const TimeZoneContainer = ({
  timeZoneOption,
  timeZone,
  div,
  patchTimeZoneMutation,
}) => {
  return (
    <div className="flex flex-col gap-y-[12px]">
      <StyledDivConfFieldLabel>TIME ZONE</StyledDivConfFieldLabel>
      <div className="mb-[-17px] w-[276px]">
        <CommonSelect
          inputName="timezone"
          placeholder="Select"
          defaultValue={timeZone}
          option={timeZoneOption}
          disabled
          labelVisible={false}
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
            patchTimeZoneMutation({
              div,
              timezone: e?.target?.value?.split(' ')[0],
            });
          }}
        />
      </div>
    </div>
  );
};

export default TimeZoneContainer;
