import styled from 'styled-components';

const StyledDivConfServiceCodeContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
`;
export default StyledDivConfServiceCodeContentWrapper;
