import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import CommonDataGrid from '../../../components/CommonDataGrid';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonMessageModal from '../../../components/CommonMessageModal/CommonMessageModal';
import CommonCustomColumnModal from '../../../components/CommonCustomColumnModal';
import AdvancedSearchButtonContent from '../../../components/Main/AdvancedSearchButtonContent';
import AdvancedSearchContent from '../../../components/Main/AdvancedSearchContent';
import HeaderButtons from '../../../components/Main/HeaderButtons';
import DEFAULT_DEFS from '../../../constants/Main/Common/defaultDefs';
import { createColumnTypes } from '../../../constants/Main/Common/mainDataGridOptions';
import useGetMainList from '../../../hooks/Main/Get/useGetMainList';
import usePatchMainColumn from '../../../hooks/Main/Patch/usePatchMainColumn';
import checkDateDiff from '../../../utils/Common/Handler/checkDateDiff';
import generateInitAdvancedForm from '../../../utils/Main/Common/Generator/generateInitAdvancedForm';
import getAvailableItems from '../../../utils/Main/Common/getAvailableItems';
import getContextMenuItems from '../../../utils/Main/Common/getContextMenuItems';
import getSelectedItems from '../../../utils/Main/Common/getSelectedItems';
import setResultColDef from '../../../utils/Main/Common/setResultColDef';
import openBCDetail from '../../../utils/Common/openBCDetail';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import mainKeys from '../../../hooks/Main/keys';

const MainWrapper = ({ category, ...props }) => {
  const queryClient = useQueryClient();
  const selectedDiv = useSelector(state => state.nowDiv);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [customizeCols, setCustomizeCols] = useState(false);
  const [allQueries, setAllQueries] = useState({
    queryString: generateInitAdvancedForm(),
    category,
    selectedDiv,
  });

  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { isLoading, data: list } = useGetMainList({
    ...allQueries,
  });

  const { mutate: mainColumnMutator } = usePatchMainColumn({
    setIsOpenAlertModal,
    setErrorMessage,
    setCustomizeCols,
  });

  const advancedSearchController = useForm({
    shouldUnregister: true,
    defaultValues: generateInitAdvancedForm(),
  });

  useEffect(() => {
    advancedSearchController.reset(generateInitAdvancedForm());
    setAllQueries({
      queryString: generateInitAdvancedForm(),
      category,
      selectedDiv,
    });
  }, [category, selectedDiv]);

  const openAdvancedSearch = () => {
    advancedSearchController.reset({ ...allQueries?.queryString });
    setAdvancedSearchOpen(true);
  };

  const gridRef = useRef(null);

  const columnDefs = useMemo(
    () =>
      setResultColDef({
        setting: list?.main_column,
        category,
      }),
    [list?.main_column],
  );

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: list?.results,
  });

  return (
    <>
      <div className="w-full h-full pr-[40px] flex flex-col">
        <HeaderButtons
          openAdvancedSearch={openAdvancedSearch}
          setCustomizeCols={setCustomizeCols}
          gridRef={gridRef}
          {...allQueries}
        />
        <CommonDataGrid
          gridRef={gridRef}
          data={list?.results}
          columnDefs={columnDefs}
          getContextMenuItems={e => {
            return getContextMenuItems({ e, category });
          }}
          columnTypes={createColumnTypes}
          onRowClicked={({ gridParam }) => {
            openBCDetail({ woNo: gridParam?.data?.wo_no });
          }}
          onFilterChange={() => {
            setChangeFlag(prev => !prev);
          }}
          onSelectionChanged={params => {
            setChangeFlag(prev => !prev);
          }}
        />
        <TotalBottomBar totalRows={totalRows} />
        {customizeCols && (
          <CommonCustomColumnModal
            openModal={customizeCols}
            setOpenModal={setCustomizeCols}
            status="Available Items"
            selectedItems={getSelectedItems({
              items: list?.main_column,
              category,
            })}
            columnItems={getAvailableItems({
              items: DEFAULT_DEFS[category],
              category,
            })}
            onApplyClick={({ buttonRef, appliedColumnList }) => {
              mainColumnMutator({
                category,
                userID: list?.user_id,
                patchData: { main_column: appliedColumnList },
                buttonRef,
              });
            }}
          />
        )}
        <FormProvider {...advancedSearchController}>
          <form
            onSubmit={advancedSearchController.handleSubmit(data => {
              if (
                !checkDateDiff({
                  fromDate: data?.['from-date'],
                  toDate: data?.['to-date'],
                })
              ) {
                setErrorMessage(
                  'The date range cannot exceed one year. Please try again.',
                );
                setIsOpenAlertModal(true);
              } else {
                setAllQueries(prev => ({ ...prev, queryString: data }));
                setAdvancedSearchOpen(false);
                advancedSearchController.reset({ ...data });

                queryClient.invalidateQueries({ queryKey: mainKeys.all });
              }
            })}
          >
            {advancedSearchOpen && (
              <CommonMessageModal
                open={advancedSearchOpen}
                setOpen={setAdvancedSearchOpen}
                header="Advanced Search"
                content={<AdvancedSearchContent category={category} />}
                buttonContent={
                  <AdvancedSearchButtonContent
                    setAdvancedSearchOpen={setAdvancedSearchOpen}
                  />
                }
              />
            )}
          </form>
        </FormProvider>
      </div>
      {isOpenAlertModal && (
        <CommonErrorModal
          open={isOpenAlertModal}
          setIsOpen={setIsOpenAlertModal}
          message={errorMessage}
        />
      )}
      {isLoading && <CommonLoading open={isLoading} />}
    </>
  );
};

export default MainWrapper;
