import { isValid } from 'date-fns';
import dayjs from 'dayjs';
import React, { forwardRef } from 'react';
import { DAILY_IN_OUT_TYPE } from '../../../../constants/BCDetail/Common/elements';

const BCDetailYardModal = forwardRef(({ yardList }, ref) => {
  return (
    <div
      ref={ref}
      className="w-[895px] z-[9998] h-[232px] bg-white fixed top-[50px] right-[10px] rounded-[10px] pb-[14px] text-[11px] font-medium shadow-md absolute"
    >
      <div className="flex flex-row items-center bg-[#001E5F] h-[28px] text-white px-[13px] rounded-t-[10px]">
        Yard
      </div>
      <div className="flex flex-row justify-between px-[32px] pt-[15px]">
        <div className="flex gap-[2px]">
          <div className="w-[126px] h-[30px] flex items-center justify-center">
            DATE TIME
          </div>
          <div className="w-[99px] h-[30px] flex items-center justify-center">
            CONTAINER #
          </div>
          <div className="w-[85px] h-[30px] flex items-center justify-center">
            YARD
          </div>
          <div className="w-[78px] h-[30px] flex items-center justify-center">
            TYPE
          </div>
          <div className="w-[108px] h-[30px] flex items-center justify-center">
            CHASSIS #
          </div>
          <div className="w-[72px] h-[30px] flex items-center justify-center">
            POOL
          </div>
          <div className="w-[95px] h-[30px] flex items-center justify-center">
            DRIVER ID
          </div>
          <div className="w-[157px] h-[30px] flex items-center justify-center">
            DRIVER NAME
          </div>
        </div>
      </div>
      <div className="h-[159px] overflow-y-scroll">
        {yardList?.map(yard => (
          <div
            key={yard?.seq}
            className="flex flex-row justify-between px-[32px] pb-[7px]"
          >
            <div className="flex gap-[2px]">
              <div className="w-[126px] h-[26px] flex items-center justify-center bg-[#F2F4F8] shadow-sm text-stone-500">
                {isValid(new Date(yard?.created_date))
                  ? dayjs(yard?.created_date).format('MM/DD/YY HH:mm')
                  : ''}
              </div>
              <div className="w-[99px] h-[26px] flex items-center justify-center bg-[#F2F4F8] shadow-sm text-stone-500">
                {yard?.cont_no}
              </div>
              <div className="w-[85px] h-[26px] flex items-center justify-center bg-[#F2F4F8] shadow-sm text-stone-500">
                {yard?.yard?.name}
              </div>
              <div className="w-[78px] h-[26px] flex items-center justify-center bg-[#F2F4F8] shadow-sm text-stone-500">
                {yard?.in_out_type ? DAILY_IN_OUT_TYPE[yard?.in_out_type] : ''}
              </div>
              <div className="w-[108px] h-[26px] flex items-center justify-center bg-[#F2F4F8] shadow-sm text-stone-500">
                {yard?.ch_no}
              </div>
              <div className="w-[72px] h-[26px] flex items-center justify-center bg-[#F2F4F8] shadow-sm text-stone-500">
                {yard?.ch_pool?.pool}
              </div>
              <div className="w-[95px] h-[26px] flex items-center justify-center bg-[#F2F4F8] shadow-sm text-stone-500">
                {yard?.driver?.driver}
              </div>
              <div className="w-[157px] h-[26px] flex items-center justify-center bg-[#F2F4F8] shadow-sm text-stone-500">
                {`${yard?.driver?.first_name || ''} ${
                  yard?.driver?.last_name || ''
                }`}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default BCDetailYardModal;
