export const FLOATING_FILTER_INPUT_SX = {
  width: '100%',
  input: {
    border: 'unset !important',
    zIndex: '9',
    WebkitTextFillColor: '#222 !important',
    paddingLeft: '2px !important',
    paddingRight: '2px !important',
    fontSize: '12px',
  },
  '& input::placeholder': {
    fontSize: '12px !important',
  },
  '.MuiInputBase-root': {
    height: '24px',
    fontSize: '12px',
    fontWeight: '500',
    '&.Mui-focused fieldset': {
      border: '1px solid #D9D9D9 !important',
    },
    '&.Mui-error fieldset': {
      border: '1.2px solid #C24D4D !important',
    },
    '&.Mui-disabled fieldset': {
      color: 'red !important',
      backgroundColor: 'unset !important',
    },
  },
  fieldset: {
    borderColor: '#D9D9D9 !important',
    backgroundColor: `#fff !important`,
  },
};
