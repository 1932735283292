import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { MAIN_COMMON_CHECK_OPTION } from '../../../../constants/Main/Common/mainCommonOptions';

const getCheckBoxDef = field => {
  return {
    valueGetter: params => {
      if (typeof params.data?.[field] === 'boolean')
        return params.data?.[field] ? 'Y' : 'N';
      return params.data?.[field];
    },
    floatingFilterComponent: FloatingFilterSelect,
    floatingFilterComponentParams: {
      option: MAIN_COMMON_CHECK_OPTION,
    },
  };
};

export default getCheckBoxDef;
