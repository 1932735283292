import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

const generateDatetimeTreePath = datetime => {
  dayjs.extend(advancedFormat);
  if (dayjs(datetime, 'MM/DD/YY HH:mm')?.isValid()) {
    const datetimeList = dayjs(datetime, 'MM/DD/YY HH:mm')
      .format('YYYY-MMMM-Do-HH-:mm')
      ?.split('-');
    return datetimeList;
  }
  return [null];
};

export default generateDatetimeTreePath;
