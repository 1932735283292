import dayjs from 'dayjs';
import { CELL_TYPE_DATETIME } from '../../../../constants/Common/DataGrid/cellType';
import {
  getDateFontColor,
  getDateFontWeight,
} from '../../../TmsReport/Common/dateStyleGenerator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import datetimeComparator from '../../../Common/Datagrid/Comparator/datetimeComparator';
import generateDatetimeFilters from '../../../Common/Datagrid/Generator/generateDatetimeFilters';

const getDatetimeDef = field => {
  return {
    cellClass: 'datetime',
    comparator: datetimeComparator,
    ...generateDatetimeFilters(),
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_DATETIME,
        customStyle: {
          color: getDateFontColor({ field, data: gridParam?.data }),
          fontWeight: getDateFontWeight({ field, data: gridParam?.data }),
        },
      }),
    valueSetter: params => {
      const dateValue = params.data?.[field];
      return dayjs(dateValue).isValid()
        ? dayjs(dateValue).format('MM/DD/YY HH:mm')
        : null;
    },
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dayjs(dateValue).isValid()
        ? dayjs(dateValue).format('MM/DD/YY HH:mm')
        : null;
    },
  };
};

export default getDatetimeDef;
