const handleNullField = ({ data, isAdd, fileFieldList, booleanFieldList }) => {
  const newData = { ...data };
  Object?.keys?.(newData)?.forEach(key => {
    const value =
      typeof newData?.[key] === 'string'
        ? newData?.[key]?.trim()
        : newData?.[key];

    const isNullField = value === undefined || value === null || value === '';

    /** Add Modal */
    if (isAdd && isNullField) {
      delete newData?.[key];
    }

    /** Edit Modal */
    if (!isAdd && isNullField) {
      if (fileFieldList?.includes?.(key) && value === null) {
        delete newData?.[key];
      } else {
        newData[key] = '';
      }

      if (booleanFieldList?.includes?.(key)) {
        newData[key] = false;
      }
    }

    /** Delete Used Field */
    if (key?.endsWith('_file_name')) {
      delete newData[key];
    }
  });

  return newData;
};

export default handleNullField;
