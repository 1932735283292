import {
  AP_GROUP,
  AR_GROUP,
  DELIVERY_GROUP,
  DOCUMENT_GROUP_EXP,
  PICK_UP_GROUP,
  PULL_OUT_GROUP,
  RETURN_GROUP,
} from '../Common/columnGroups';

const EXPORT_DEFAULT_FIELDS = {
  wms: {},
  category: {},
  created_date: {},
  wo_no: {},
  billto: {},
  cont_no: {},
  ssl: {},
  size: {},
  type: {},
  ch_no: {},
  pool: {},
  ch_type: {},
  bond_entry: {},
  ref_no: {},
  purchase_no: {},
  wgt: {},
  qty: {},
  over_height: {},
  over_weight: {},
  hazmat: {},
  hot: {},
  bk_no: {},
  st: {},
  erd: {},
  cutoff: {},
  rfd: {},
  po_g: { children: PULL_OUT_GROUP },
  dilv_g: { children: DELIVERY_GROUP },
  pu_g: { children: PICK_UP_GROUP },
  rtn_g: { children: RETURN_GROUP },
  empty_load_cfm: {},
  ar_g: { children: AR_GROUP },
  ap_g: { children: AP_GROUP },
  profit: {},
  inv: {},
  doc_g: { children: DOCUMENT_GROUP_EXP },
  sendto: {},
  remarks: {},
};

export { EXPORT_DEFAULT_FIELDS };
