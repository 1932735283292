import dayjs from 'dayjs';
import React, { useState } from 'react';
import {
  IMP_EXP_OPTIONS,
  IN_OUT_OPTIONS,
  LOAD_EMPTY_OPTIONS,
  TYPE_322_OPTIONS,
  UNIT_322_OPTIONS,
} from '../../../../../constants/EDI/options';
import CommonDate from '../../../../Inputs/CommonDate/CommonDate';
import CommonSelect from '../../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';
import CommonTime from '../../../../Inputs/CommonTime/CommonTime';

const ModalContent = () => {
  const [mandatory, setMandatory] = useState('MBL');

  return (
    <div className="w-[669px] h-[378px] bg-[#F9F9F9]">
      <div className="flex justify-center items-center ">
        <div className="ml-[23px] mr-[20px] mt-[27px]">
          <CommonSelect
            label="IMP / EXP"
            inputName="imp_exp"
            width="w-[195px]"
            option={IMP_EXP_OPTIONS}
            defaultValue="IMPORT"
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e);
              if (e?.target?.value === 'I') {
                setMandatory('MBL');
              }
              if (e?.target?.value === 'O') {
                setMandatory('Booking');
              }
            }}
          />
        </div>
        <div className="mr-[20px] mt-[27px]">
          <CommonSelect
            label="IN / OUT"
            inputName="in_out"
            width="w-[195px]"
            required
            option={IN_OUT_OPTIONS}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e);
            }}
          />
        </div>
        <div className="mr-[20px] mt-[27px]">
          <CommonSelect
            label="LOAD / EMPTY"
            inputName="load_empty"
            width="w-[195px]"
            defaultValue="EMPTY"
            option={LOAD_EMPTY_OPTIONS}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e);
            }}
          />
        </div>
      </div>
      <div className="mt-[8px] flex justify-center items-center ">
        <div className="ml-[23px] mr-[20px]">
          <CommonTextInput
            label="CONT #"
            inputName="cont_no"
            width="w-[195px]"
            maxLength={12}
            required
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
        <div className="mr-[20px]">
          <CommonTextInput
            label="MBL #"
            inputName="mbl_no"
            width="w-[195px]"
            maxLength={50}
            required={mandatory === 'MBL'}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
        <div className="mr-[20px]">
          <CommonTextInput
            label="BOOKING #"
            inputName="bk_no"
            width="w-[195px]"
            maxLength={50}
            required={mandatory === 'Booking'}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div className="mt-[8px] flex justify-center items-center ">
        <div className="ml-[23px] mr-[20px]">
          <CommonTextInput
            label="WEIGHT"
            inputName="wgt"
            width="w-[195px]"
            maxLength={10}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
        <div className="mr-[20px]">
          <CommonSelect
            label="UNIT"
            inputName="unit"
            width="w-[195px]"
            option={UNIT_322_OPTIONS}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e);
            }}
          />
        </div>
        <div className="mr-[20px]">
          <CommonSelect
            label="TYPE"
            inputName="type"
            width="w-[195px]"
            option={TYPE_322_OPTIONS}
            onChangeHandler={({ e, onChange }) => {
              onChange?.(e);
            }}
          />
        </div>
      </div>
      <div className="mt-[8px] flex justify-center items-center ">
        <div className="ml-[23px] mr-[20px]">
          <CommonDate
            label="DATE"
            inputName="transaction_date"
            width="w-[141px]"
            required
            onChangeHandler={({ e, onChange }) => {
              const value = dayjs(e)?.isValid()
                ? dayjs(e)?.format('YYYY-MM-DD')
                : null;
              onChange?.(value);
            }}
          />
        </div>
        <div className="mr-[20px]">
          <CommonTime
            label="TIME"
            inputName="transaction_time"
            width="w-[141px]"
            required
            onChangeHandler={({ e, onChange }) => {
              if (dayjs(e).isValid()) onChange?.(dayjs(e).format('HH:mm:ss'));
              else onChange?.(null);
            }}
          />
        </div>
        <div className="mr-[20px]">
          <CommonTextInput
            label="SEAL #"
            inputName="seal_no"
            width="w-[141px]"
            maxLength={30}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
        <div className="mr-[20px]">
          <CommonTextInput
            label="VOYAGE #"
            inputName="voyage_no"
            width="w-[141px]"
            maxLength={10}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div className="mt-[8px] flex justify-center items-center ">
        <div className="ml-[23px] mr-[20px]">
          <CommonTextInput
            label="VESSEL CODE"
            inputName="vessel_code"
            width="w-[302px]"
            maxLength={10}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
        <div className="mr-[20px]">
          <CommonTextInput
            label="VESSEL NAME"
            inputName="vessel_name"
            width="w-[302px]"
            maxLength={30}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalContent;
