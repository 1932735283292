import dayjs from 'dayjs';
import { CELL_TYPE_DATE } from '../../../../constants/Common/DataGrid/cellType';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import isDisabled from './isDisabled';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import getCellStyle from './getCellStyle';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import generateDateFilters from '../../../Common/Datagrid/Generator/generateDateFilters';

const getEditableDateDef = ({ field, scheduleType }) => {
  return {
    cellClass: 'date',
    comparator: dateComparator,
    ...generateDateFilters(),
    cellEditorSelector: gridParam =>
      cellEditorGenerator({
        gridParam,
        cellType: CELL_TYPE_DATE,
        disabled: isDisabled({ gridParam, scheduleType }),
      }),
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_DATE,
        customStyle: getCellStyle({ ...gridParam }),
      }),
    valueSetter: params => {
      const input = params?.newValue
        ? dayjs(params?.newValue).format('YYYY-MM-DD')
        : null;
      if (params.data?.[field] !== input) {
        params.data[field] = input;
        return true;
      }
      return false;
    },
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
    },
  };
};

export default getEditableDateDef;
