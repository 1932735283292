/**
 * @description
 * Function that generate Number Sign Column Definition
 * @returns
 * Object that contains the Number Sign Column Definition
 */
const generateNoDef = ({ columns, foldable, ...props }) => {
  const isPinnedNoDef = columns?.some(col => !!col?.pinned);

  const noDef = {
    headerName: 'NO',
    field: 'no',
    valueGetter: gridParam => {
      if (gridParam?.node?.rowPinned) {
        return null;
      }

      if (foldable) {
        const currentRowIndex = gridParam?.node?.rowIndex;
        const rowModel = gridParam?.api.rowModel;

        const detailRowIndexList = rowModel.rowsToDisplay
          .filter(node => node.detail)
          .map(node => node.rowIndex);

        const countBefore =
          detailRowIndexList?.filter(value => value < currentRowIndex)
            ?.length || 0;

        return currentRowIndex - countBefore + 1;
      }

      return gridParam.node.rowIndex + 1;
    },
    width: 30,
    sortable: false,
    filter: false,
    floatingFilter: false,
    editable: false,
    suppressRowClickSelection: true,
    suppressMovable: true,
    onCellDoubleClicked: null,
    lockPosition: true,
    ...(isPinnedNoDef && { pinned: 'left' }),
  };

  return noDef;
};

export default generateNoDef;
