import dayjs from 'dayjs';
import { REGEX_ONLY_NUMBER } from '../../../../constants/Common/regex';

const handleDateKeyUpCapture = ({ e, onChange, ...handlerProps }) => {
  // Prevent backspace, Delete, Tab.
  if (
    e?.key === 'Backspace' ||
    e?.keyCode === 8 ||
    e?.key === 'Delete' ||
    e?.keyCode === 46 ||
    e?.key === 'Tab' ||
    e?.keyCode === 9
  )
    return;

  const inputValue = e.target.value;

  // Split date value.
  const [month, date, year] = inputValue?.split('/');

  let currDate = null;
  if (
    month &&
    date &&
    year &&
    REGEX_ONLY_NUMBER.test(month) &&
    REGEX_ONLY_NUMBER.test(date)
  ) {
    if (!REGEX_ONLY_NUMBER.test(year)) {
      // Create date based on current year.
      currDate = dayjs(`${dayjs().year()}-${month}-${date}`);
    }
    if (REGEX_ONLY_NUMBER.test(year)) {
      // Create date based on current year.
      currDate = dayjs(`${2000 + Number(year)}-${month}-${date}`);
    }
  }

  if (currDate && dayjs(currDate)?.isValid()) onChange?.(currDate);
  else onChange?.(null);
};

export default handleDateKeyUpCapture;
