import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DISPATCH_PAGE_EXPORT_BOOKING,
  DISPATCH_TYPE_BOOKING,
} from '../../../constants/Dispatch/Common/category';
import { columnTypes } from '../../../constants/Dispatch/Common/dataGridOptions';
import { DISPATCH_BOOKING_COLUMNS } from '../../../constants/Dispatch/Export/Common/exportColList';
import useGetCompanyOption from '../../../hooks/Dispatch/Common/useGetCompanyOption';
import useGetBookingDetail from '../../../hooks/Dispatch/Export/Booking/useGetBookingDetail';
import useGetBookingWO from '../../../hooks/Dispatch/Export/Booking/useGetBookingWO';
import useGetDispatchList from '../../../hooks/Dispatch2/Common/Get/useGetDispatchList';
import StyledDispatchDataGrid from '../../../styles/Dispatch/Common/StyledDispatchDataGrid';
import {
  createFloatingFilterSelectOption,
  createGridSelectOption,
} from '../../../utils/Common/createFloatingFilterSelectOption';
import setResultColDef from '../../../utils/Dispatch/Common/Generator/setResultColDef';
import CommonDataGrid from '../../CommonDataGrid';
import AddBookingModal from '../BookingModal/AddBookingModal';
import UpdateBookingModal from '../BookingModal/UpdateBookingModal';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';
import TotalBottomBar from '../../Common/CommonTotalBottomBar';

const DispatchBooking = ({
  division,
  queryObject,
  gridRef,
  setIsMessageModalOpen,
  setMessageModalMessage,
  setIsLoadingOpen,
  openers,
}) => {
  const patchProps = {
    setIsMessageModalOpen,
    setMessageModalMessage,
    setIsLoadingOpen,
  };

  /** Dropdown Data */
  const getSSL = useSelector(state => state.common.ssl);
  const getSize = useSelector(state => state.common.size);
  const getType = useSelector(state => state.common.type);

  const { billTo, loc, query: queryGetCompanyOption } = useGetCompanyOption();

  const [defaultOption, setDefaultOption] = useState({
    ssl: createFloatingFilterSelectOption(getSSL?.map(item => item.ssl)),
    size: createGridSelectOption(getSize?.map(item => item.size)),
    type: createGridSelectOption(getType?.map(item => item.type)),
    billTo,
    loc,
    div: division,
  });

  useEffect(() => {
    setDefaultOption({
      ssl: createFloatingFilterSelectOption(getSSL?.map(item => item.ssl)),
      size: createGridSelectOption(getSize?.map(item => item.size)),
      type: createGridSelectOption(getType?.map(item => item.type)),
      billTo,
      loc,
      div: division,
    });
  }, [getSSL, getSize, getType, billTo, loc, division]);

  /** Booking List */
  const { dispatchList, getListLoading } = useGetDispatchList({
    division,
    status: DISPATCH_PAGE_EXPORT_BOOKING,
    category: DISPATCH_TYPE_BOOKING,
    queryObject,
    isBooking: true,
  });

  /** DataGrid Column Definition */
  const [columnDefs, _] = useState(
    setResultColDef({
      status: DISPATCH_PAGE_EXPORT_BOOKING,
      rowData: { dsp_column: DISPATCH_BOOKING_COLUMNS },
    }),
  );

  /* State for Editing */
  const [bookingSeq, setBookingSeq] = useState(null);
  const [isUpdateBookingModalOpen, setIsUpdateBookingModalOpen] =
    useState(false);

  useEffect(() => {
    if (!isUpdateBookingModalOpen) {
      setBookingSeq(null);
    }
  }, [isUpdateBookingModalOpen]);

  /** Editing Modal Data */
  const {
    bookingDetail,
    bookingHistory,
    isLoading: isLoadingBookingDetail,
  } = useGetBookingDetail({ bookingSeq });

  const { bookingWO, isLoading: isLoadingBookingWO } = useGetBookingWO({
    bookingSeq,
  });

  /** Loading */
  const waitingList = [
    isLoadingBookingDetail,
    isLoadingBookingWO,
    getListLoading,
  ];

  useEffect(() => {
    setIsLoadingOpen(![...waitingList].every(value => value === false));
  }, [waitingList]);

  /** Total Bottom Bar Data */
  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: dispatchList,
  });

  /** Show/Hide DataGrid CheckBox */
  const isRowSelectable = useCallback(params => {
    return !!params?.data?.ava_complete;
  }, []);

  /** Event Handler for Click Cell */
  const clickCell = ({ gridParam }) => {
    if (gridParam?.column?.colId !== 'doc') {
      setBookingSeq(gridParam?.data?.seq);
      setIsUpdateBookingModalOpen(true);
    }
  };

  return (
    <>
      <StyledDispatchDataGrid>
        {!!columnDefs.length && (
          <>
            <CommonDataGrid
              gridRef={gridRef}
              data={dispatchList}
              columnDefs={columnDefs}
              columnTypes={columnTypes}
              onCellClicked={clickCell}
              isRowSelectable={isRowSelectable}
              onFilterChange={() => {
                setChangeFlag(prev => !prev);
              }}
              onSelectionChanged={() => {
                setChangeFlag(prev => !prev);
              }}
              onGridReady={() => {
                setChangeFlag(prev => !prev);
              }}
            />
            <TotalBottomBar totalRows={totalRows} />
          </>
        )}
      </StyledDispatchDataGrid>
      {isUpdateBookingModalOpen && (
        <UpdateBookingModal
          setBookingSeq={setBookingSeq}
          bookingSeq={bookingSeq}
          queryObject={queryObject}
          editData={bookingDetail}
          bookingHistory={bookingHistory}
          editWOData={bookingWO}
          isUpdateBookingModalOpen={isUpdateBookingModalOpen}
          setIsUpdateBookingModalOpen={setIsUpdateBookingModalOpen}
          isLoadingBillToLoc={
            queryGetCompanyOption[0]?.isLoading ||
            queryGetCompanyOption[1]?.isLoading
          }
          {...openers}
          {...defaultOption}
          {...patchProps}
        />
      )}
      {openers?.isTabModalOpen && (
        <AddBookingModal
          isLoadingBillToLoc={
            queryGetCompanyOption[0]?.isLoading ||
            queryGetCompanyOption[1]?.isLoading
          }
          {...openers}
          {...defaultOption}
          {...patchProps}
        />
      )}
    </>
  );
};

export default DispatchBooking;
