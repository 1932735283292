import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import StyledCustomerReportWrapper from '../../../styles/CustomerReport/Common/StyledCustomerReportWrapper';
import TopUtilityBar from '../../../components/CustomerReport/TopUtilityBar';
import ReportDataGrid from '../../../components/CustomerReport/ReportDataGrid';
import generateReportGridColumnDefinition from '../../../utils/CustomerReport/Generator/generateReportGridColumnDefinition';
import useGetSettingData from '../../../hooks/CustomerReport/CustomerReportSetting/Get/useGetSettingData';
import generateSettingList from '../../../utils/CustomerReport/Generator/generateSettingList';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import useDeleteSetting from '../../../hooks/CustomerReport/CustomerReportSetting/Delete/useDeleteSetting';
import { GRID_MOD_DELETE } from '../../../constants/Common/DataGrid/setting';
import SendEmailModal from '../../../components/CustomerReport/SendEmailModal';
import useControlLoading from '../../../hooks/CustomerReport/CustomerReportSetting/Control/useControlLoading';
import SettingDetailTable from '../../../components/CustomerReport/SettingDetailTable';
import EmailConfirmModal from '../../../components/CustomerReport/Common/EmailConfirmModal';
import DeleteConfirmModal from '../../../components/CustomerReport/DeleteConfirmModal';
import expandSubTable from '../../../utils/CustomerReport/Handler/expandSubTable';
import usePostEmailList from '../../../hooks/CustomerReport/CustomerReportSetting/Post/usePostEmailList';
import TotalBottomBar from '../../../components/Common/CommonTotalBottomBar';
import useControlTotalInfo from '../../../hooks/Common/TotalBottomRow/useControlTotalInfo';

const CustomerReportSetting = ({ ...props }) => {
  /** division List */
  const div = useSelector(state => state?.nowDiv);

  /** Customer Report Page */
  const { subCategory } = props;

  /** Modal */
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  const alertModalOptions = {
    setMessage,
    setIsSuccess,
    setIsOpenErrorModal,
  };

  /** Email Modal */
  const [isSendEmailModal, setIsSendEmailModal] = useState(false);
  const [sendedEmailInfo, setSendedEmailInfo] = useState(null);

  /** Email Confirm Modal */
  const [isOpenEmailConfirmModal, setIsOpenEmailConfirmModal] = useState(false);
  const [confirmEmailListSeq, setConfirmEmailListSeq] = useState(null);
  const [confirmEmail, setConfirmEmail] = useState('');

  /** DataGrid */
  const gridRef = useRef(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);

  /** DataGrid Opened Row Index */
  const [expandedRowNameList, setExpandedRowNameList] = useState([]);

  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState(false);
  const [deleteMutator, setDeleteMutator] = useState(null);

  const confirmDeleteOptions = {
    setIsOpenDeleteConfirmModal,
    setDeleteMutator,
  };

  /** Get List Name & User Information List */
  const { settingData, isLoading } = useGetSettingData({ div });

  /** Post Email List */
  const { mutate: postEmailListMutate } = usePostEmailList({
    setIsOpenErrorModal,
    setMessage,
    setIsSuccess,
  });

  /** Delete List Name */
  const { mutate: deleteSettingMutate } = useDeleteSetting({
    div,
    gridRef,
    setIsOpenErrorModal,
    setMessage,
    setIsSuccess,
    setExpandedRowNameList,
  });

  /** Set DataGrid Data */
  useEffect(() => {
    setColumnDefs(
      generateReportGridColumnDefinition({
        subCategory,
      }),
    );
  }, []);

  useEffect(() => {
    setRowData(generateSettingList({ settingData }));
  }, [settingData]);

  /** dataGrid Event Handler */
  const handleRowDeleted = ({ gridParam }) => {
    const newDeleteMutator = () =>
      deleteSettingMutate({
        deleteData: { seq: [gridParam?.data?.seq] },
        gridParam,
      });
    setDeleteMutator(() => newDeleteMutator);
    setIsOpenDeleteConfirmModal(true);
  };

  const { isLoadingOpen } = useControlLoading({
    isLoadingList: [isLoading],
  });

  useEffect(() => {
    if (expandedRowNameList) {
      expandSubTable({ gridRef, expandedRowNameList });
    }
  }, [rowData]);

  const memoizedSettingDetailTable = useCallback(
    foldProps => (
      <SettingDetailTable
        setIsOpenErrorModal={setIsOpenErrorModal}
        setMessage={setMessage}
        setIsOpenEmailConfirmModal={setIsOpenEmailConfirmModal}
        setConfirmEmail={setConfirmEmail}
        setIsSuccess={setIsSuccess}
        confirmDeleteOptions={confirmDeleteOptions}
        setIsSendEmailModal={setIsSendEmailModal}
        setSendedEmailInfo={setSendedEmailInfo}
        setConfirmEmailListSeq={setConfirmEmailListSeq}
        {...foldProps}
      />
    ),
    [],
  );

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: rowData,
  });

  return (
    <>
      <StyledCustomerReportWrapper>
        <TopUtilityBar
          subCategory={subCategory}
          gridRef={gridRef}
          deleteSettingMutate={deleteSettingMutate}
          confirmDeleteOptions={confirmDeleteOptions}
          alertModalOptions={alertModalOptions}
        />
        <ReportDataGrid
          data={rowData}
          gridRef={gridRef}
          columnDefs={columnDefs}
          modType={GRID_MOD_DELETE}
          foldable
          foldableContent={memoizedSettingDetailTable}
          onDeleteClicked={handleRowDeleted}
          onFilterChange={() => {
            setChangeFlag(prev => !prev);
          }}
          onSelectionChanged={() => {
            setChangeFlag(prev => !prev);
          }}
          onGridReady={() => {
            setChangeFlag(prev => !prev);
          }}
        />
        <TotalBottomBar totalRows={totalRows} />
      </StyledCustomerReportWrapper>
      {isSendEmailModal && (
        <SendEmailModal
          isSendEmailModal={isSendEmailModal}
          setIsSendEmailModal={setIsSendEmailModal}
          sendedEmailInfo={sendedEmailInfo}
          postEmailListMutate={postEmailListMutate}
        />
      )}
      {isLoadingOpen && <CommonLoading open={isLoadingOpen} />}
      {isOpenErrorModal && !isSuccess && (
        <CommonErrorModal
          open={isOpenErrorModal}
          setIsOpen={setIsOpenErrorModal}
          message={message}
          isSuccess={isSuccess}
        />
      )}
      {/* Email Confirmation Modal */}
      {isOpenEmailConfirmModal && (
        <EmailConfirmModal
          open={isOpenEmailConfirmModal}
          setOpen={setIsOpenEmailConfirmModal}
          email={confirmEmail}
          onSaveClick={({ buttonRef }) => {
            const postData = {
              email: [confirmEmail],
              list_seq: confirmEmailListSeq,
            };
            postEmailListMutate({
              postData,
              buttonRef,
            });
            setIsOpenEmailConfirmModal(false);
          }}
        />
      )}
      {isOpenDeleteConfirmModal && (
        <DeleteConfirmModal
          isOpenDeleteConfirmModal={isOpenDeleteConfirmModal}
          setIsOpenDeleteConfirmModal={setIsOpenDeleteConfirmModal}
          deleteMutator={deleteMutator}
        />
      )}
    </>
  );
};

export default CustomerReportSetting;
