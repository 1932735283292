import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BUTTON_COLS } from '../../../constants/Dispatch/Common/buttonColList';
import {
  DISPATCH_PAGE_BROKERAGE_AVAILABLE,
  DISPATCH_PAGE_BROKERAGE_DELIVERY,
  DISPATCH_PAGE_BROKERAGE_RETURN,
  DISPATCH_PAGE_EXPORT_AVAILABLE,
  DISPATCH_PAGE_EXPORT_DELIVERY,
  DISPATCH_PAGE_EXPORT_RETURN,
  DISPATCH_PAGE_EXPORT_UNAVAILABLE,
  DISPATCH_PAGE_IMPORT_AVAILABLE,
  DISPATCH_PAGE_IMPORT_DELIVERY,
  DISPATCH_PAGE_IMPORT_RETURN,
  DISPATCH_PAGE_IMPORT_UNAVAILABLE,
  DISPATCH_PAGE_VAN_AVAILABLE,
  DISPATCH_PAGE_VAN_DELIVERY,
  DISPATCH_PAGE_VAN_RETURN,
} from '../../../constants/Dispatch/Common/category';
import { STATUS_EDITABLE_COLS } from '../../../constants/Dispatch/Common/editableColList';
import {
  INIT_FILTERS,
  LOCATION_TAB_FILTERS,
} from '../../../constants/Dispatch/Common/filterButtonSettings';
import { DISPATCH_STATUS_VALUE } from '../../../constants/Dispatch/Common/statusValue';
import useGetAPInput from '../../../hooks/Dispatch/Common/useGetAPInput';
import useGetDriverList from '../../../hooks/Dispatch/Common/useGetDriverList';
import usePatchCompleteWO from '../../../hooks/Dispatch/Common/usePatchCompleteWO';
import usePatchRowData from '../../../hooks/Dispatch/Common/usePatchRowData';
import usePostConvertToExport from '../../../hooks/Dispatch/Common/usePostConvertToExport';
import useGetBookingOptionMutation from '../../../hooks/Dispatch/Export/Booking/useGetBookingOptionMutation';
import { createGridSelectOption } from '../../../utils/Common/createFloatingFilterSelectOption';
import convertStatus from '../../../utils/Dispatch/Common/Generator/convertStatus';
import handleCellClick from '../../../utils/Dispatch/Common/Handler/handleCellClick';
import handleSaveClick from '../../../utils/Dispatch/Common/Handler/handleSaveClick';
import APModal from '../APModal';
import DriverModal from '../DriverModal';

import BrokerageAvailable from './Brokerage/BrokerageAvailable';
import BrokerageDelivery from './Brokerage/BrokerageDelivery';
import BrokerageReturn from './Brokerage/BrokerageReturn';
import ExportAvailable from './Export/ExportAvailable';
import ExportDelivery from './Export/ExportDelivery';
import ExportReturn from './Export/ExportReturn';
import ExportUnavailable from './Export/ExportUnavailable';
import ImportAvailable from './Import/ImportAvailable';
import ImportDelivery from './Import/ImportDelivery';
import ImportReturn from './Import/ImportReturn';
import ImportUnavailable from './Import/ImportUnavailable';
import VanAvailable from './Van/VanAvailable';
import VanDelivery from './Van/VanDelivery';
import VanReturn from './Van/VanReturn';
import DriverChangeConfirmModal from '../DriverChangeConfirmModal';

const DispatchGrid = ({
  gridRef,
  subFilter,
  status,
  locStatus,
  rowData,
  isEditable,
  setMessageModalMessage,
  setIsMessageModalOpen,
  setIsLoadingOpen,
}) => {
  /* Options for Grid */
  const getPool = useSelector(state => state.common.pool);
  const [gridPool, setGridPool] = useState(
    createGridSelectOption(getPool?.map(item => item.pool)),
  );
  useEffect(() => {
    setGridPool(createGridSelectOption(getPool?.map(item => item.pool)));
  }, [getPool]);

  const [bookingOption, setBookingOption] = useState([]);

  /* States for Row Edit */
  const [woNo, setWONo] = useState('');
  const [beforeEditData, setBeforeEditData] = useState({});

  /* States for Driver/AP Modal */
  const [isDriverChangeModalOpen, setIsDriverChangeModalOpen] = useState(false);
  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
  const [isAPModalOpen, setIsAPModalOpen] = useState(false);
  const [selectedDriverData, setSelectedDriverData] = useState(null);
  const [selectedScheduleSeq, setSelectedScheduleSeq] = useState(null);

  const messageModalProps = {
    setIsMessageModalOpen,
    setMessageModalMessage,
    setIsLoadingOpen,
  };

  const [focusedCell, setFocusedCell] = useState({});
  const [editScrollTop, setEditScrollTop] = useState(null);

  const restoreFocusedCell = ({ ...props }) => {
    if (focusedCell?.rowIndex !== null && focusedCell?.column !== null) {
      setTimeout(() => {
        const gridBodyContainer = document.querySelector('.ag-body-viewport');

        gridRef?.current?.api?.ensureIndexVisible(focusedCell.rowIndex);
        gridRef?.current?.api?.ensureColumnVisible(focusedCell.column);
        gridRef?.current?.api?.setFocusedCell({
          rowIndex: focusedCell.rowIndex,
          colKey: focusedCell.column,
        });

        if (gridBodyContainer && editScrollTop) {
          gridBodyContainer.scrollTop = editScrollTop;
        }
      }, 0);
    }
  };

  /* Hooks for Edit, Booking Option and Driver/AP Modal */
  const getBookingOption = useGetBookingOptionMutation({
    setBookingOption,
  });

  const { driverList } = useGetDriverList({ woNo });

  const {
    apInputData,
    refetch: getAPRefetcher,
    isFetching: getAPIsFetching,
    isLoading: getAPIsLoading,
    isError: getAPIsError,
  } = useGetAPInput({
    woNo,
    scheduleSeq: selectedScheduleSeq,
    driver: selectedDriverData?.driver,
    setAPModalOpener: setIsAPModalOpen,
    setIsDriverModalOpen,
    ...messageModalProps,
  });

  const { mutate } = usePatchRowData({
    focusedCell,
    restoreFocusedCell,
    gridRef,
    ...messageModalProps,
  });

  const { mutate: mutateComplete, isPending: completeIsPending } =
    usePatchCompleteWO({
      ...messageModalProps,
    });

  const convertToExport = usePostConvertToExport({
    ...messageModalProps,
  });

  useEffect(() => {
    localStorage.setItem('bookingOption', JSON.stringify(bookingOption));
    const instances = gridRef?.current?.api?.getCellEditorInstances();
    const bookingInstance = instances?.find(
      element => element?.cellEditorParams?.column?.colId === 'bk_no',
    );
    bookingInstance?.componentInstance?.setOption?.(bookingOption);
    bookingInstance?.componentInstance?.setIsLoading?.(
      getBookingOption.isIdle || getBookingOption.isPending,
    );
  }, [bookingOption, getBookingOption.isIdle, getBookingOption.isPending]);

  const waitingList = [
    completeIsPending,
    getAPIsLoading,
    convertToExport.isPending,
  ];
  useEffect(() => {
    setIsLoadingOpen(![...waitingList].every(value => value === false));
  }, [...waitingList]);

  /* Functions for Grid */
  const editableCols = isEditable ? STATUS_EDITABLE_COLS?.[status] : [];
  const [clickFocusedCell, setClickFocusedCell] = useState(null);

  const clickSave = gridParam => {
    const currentScrollTop = gridParam?.api?.getVerticalPixelRange()?.top;
    setEditScrollTop(currentScrollTop);

    setFocusedCell({
      rowIndex: gridParam?.rowIndex,
      column: gridParam?.api.getFocusedCell()?.column.getId(),
    });
    handleSaveClick({
      beforeEditData,
      gridParam,
      editableCols,
      bookingOption,
      ...messageModalProps,
      mutate,
      dispatchStatus: DISPATCH_STATUS_VALUE?.[status],
      category: convertStatus({ status }),
    });
  };

  const clickCell = ({ gridParam }) => {
    if (
      !clickFocusedCell ||
      clickFocusedCell?.rowIndex === gridParam?.rowIndex
    ) {
      handleCellClick({
        gridParam,
        gridRef,
        editableCols,
        buttonCols: BUTTON_COLS?.[status],
      });
    } else {
      setClickFocusedCell(null);
    }
  };

  const handleComplete = e => {
    if (status?.includes('rtn')) {
      mutateComplete({ wo: e?.data?.wo_no });
    }
  };

  const handleConvert = e => {
    convertToExport.mutate({ woNo: e?.data?.wo_no });
  };

  const handleRowEditingStart = ({ gridParam }) => {
    const buttonInstance = gridParam?.api?.getCellRendererInstances({
      rowNodes: [gridParam?.node],
      columns: BUTTON_COLS?.[status],
    });

    buttonInstance?.forEach(
      item => !item?.colDef?.suppressChangeDisabled && item?.setDisabled(true),
    );

    setClickFocusedCell(gridRef.current.api.getFocusedCell());
    setBeforeEditData({ ...gridParam.data });

    /** Get Booking No List */
    if (
      [
        DISPATCH_PAGE_EXPORT_UNAVAILABLE,
        DISPATCH_PAGE_EXPORT_AVAILABLE,
      ].includes(status)
    ) {
      getBookingOption.mutate({
        woNo: gridParam?.data?.wo_no,
        size: gridParam?.data?.size,
        type: gridParam?.data?.type,
        ssl: gridParam?.data?.ssl,
      });
    }
  };

  const handleRowEditingStop = ({ gridParam }) => {
    const buttonInstance = gridParam?.api?.getCellRendererInstances({
      rowNodes: [gridParam?.node],
      columns: BUTTON_COLS?.[status],
    });

    buttonInstance?.forEach(
      item => !item?.colDef?.suppressChangeDisabled && item?.setDisabled(false),
    );

    if (
      status === DISPATCH_PAGE_EXPORT_AVAILABLE ||
      status === DISPATCH_PAGE_EXPORT_UNAVAILABLE
    ) {
      setBookingOption([]);
    }
  };

  const editParam = {
    pool: gridPool,
    complete: handleComplete,
    export_btn: handleConvert,
  };

  const gridProps = {
    gridRef,
    rowData,
    isEditable,
    status: LOCATION_TAB_FILTERS?.includes(subFilter) ? locStatus : status,
    clickCell,
    clickSave,
    handleRowEditingStart,
    handleRowEditingStop,
    editParam,
    subFilter,
  };

  const buttonCellProps = {
    setIsDriverChangeModalOpen,
    setIsDriverModalOpen,
    setWONo,
    setSelectedScheduleSeq,
    ...messageModalProps,
  };

  useEffect(() => {
    if (focusedCell?.rowIndex && focusedCell?.column) {
      restoreFocusedCell();
    }
  }, [rowData?.result]);

  const onConfirmChangeDriver = () => {
    setIsDriverModalOpen(true);
  };

  return (
    <>
      {status === DISPATCH_PAGE_IMPORT_UNAVAILABLE && (
        <ImportUnavailable {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_IMPORT_AVAILABLE && (
        <ImportAvailable {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_IMPORT_DELIVERY && (
        <ImportDelivery {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_IMPORT_RETURN && (
        <ImportReturn {...gridProps} {...buttonCellProps} />
      )}

      {status === DISPATCH_PAGE_EXPORT_UNAVAILABLE && (
        <ExportUnavailable {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_EXPORT_AVAILABLE && (
        <ExportAvailable {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_EXPORT_DELIVERY && (
        <ExportDelivery {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_EXPORT_RETURN && (
        <ExportReturn {...gridProps} {...buttonCellProps} />
      )}

      {status === DISPATCH_PAGE_VAN_AVAILABLE && (
        <VanAvailable {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_VAN_DELIVERY && (
        <VanDelivery {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_VAN_RETURN && (
        <VanReturn {...gridProps} {...buttonCellProps} />
      )}

      {status === DISPATCH_PAGE_BROKERAGE_AVAILABLE && (
        <BrokerageAvailable {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_BROKERAGE_DELIVERY && (
        <BrokerageDelivery {...gridProps} {...buttonCellProps} />
      )}
      {status === DISPATCH_PAGE_BROKERAGE_RETURN && (
        <BrokerageReturn {...gridProps} {...buttonCellProps} />
      )}
      <DriverModal
        setWONo={setWONo}
        driverAPModalOpener={isDriverModalOpen}
        setDriverAPModalOpener={setIsDriverModalOpen}
        setAPModalOpener={setIsAPModalOpen}
        setSelectedScheduleSeq={setSelectedScheduleSeq}
        setSelectedDriverData={setSelectedDriverData}
        getAPRefetcher={getAPRefetcher}
        driverList={driverList}
        selectedDriverData={selectedDriverData}
        getAPIsError={getAPIsError}
      />
      {isDriverChangeModalOpen && (
        <DriverChangeConfirmModal
          open={isDriverChangeModalOpen}
          setOpen={setIsDriverChangeModalOpen}
          onConfirm={onConfirmChangeDriver}
        />
      )}
      <APModal
        woNo={woNo}
        setWONo={setWONo}
        apModalOpener={isAPModalOpen}
        setAPModalOpener={setIsAPModalOpen}
        setSelectedScheduleSeq={setSelectedScheduleSeq}
        setSelectedDriverData={setSelectedDriverData}
        getAPIsFetching={getAPIsFetching}
        getAPIsError={getAPIsError}
        apInputData={apInputData}
        selectedScheduleSeq={selectedScheduleSeq}
        setMessageModalOpener={setIsMessageModalOpen}
        setMessageModalMessage={setMessageModalMessage}
        setLoadingOpener={setIsLoadingOpen}
        gridRef={gridRef}
        focusedCell={focusedCell}
        restoreFocusedCell={restoreFocusedCell}
      />
    </>
  );
};

export default DispatchGrid;
