import COLUMN_ITEM_PAIR from '../../../constants/Common/columnPair';
import {
  ERROR_ADDRESS_INVALID,
  ERROR_CANNOT_DELETE_LOCATION,
  ERROR_COMPLETE_WO_DELETE,
  ERROR_CONVERTED_EXPORT_WO_DELETE,
  ERROR_DATA_CONFLICTED,
  ERROR_DIVISION_HAS_WO,
  ERROR_DUAL_JOB_REASSIGN,
  ERROR_EDI_204_UPDATE,
  ERROR_EXISTING_AP_AR_DELETE,
  ERROR_GET_VEHICLE_FAILED,
  ERROR_GROUP_HAS_USER,
  ERROR_INVALID_LOCATION,
  ERROR_MAIN_PERMISSION_MISSING,
  ERROR_NAVIGATION_FAILED,
  ERROR_NOT_AVAILABLE,
  ERROR_NOT_SUPPORTED_FILE_SIZE,
  ERROR_PERMISSION_FIRST_PAGE_MISMATCH,
  ERROR_PERMISSION_MISSING_FOR_FIRST_PAGE,
  ERROR_PREVIOUS_ACTUAL_ERROR,
  ERROR_QB_AUTH_CODE,
  ERROR_QB_DELETED,
  ERROR_QB_LOCKED,
  ERROR_QB_NON_ZERO_BALANCE,
  ERROR_QB_TIMEOUT,
  ERROR_RETURN_LOCATION_NOT_FOUND,
  ERROR_RETURN_SCHEDULE_UPDATE_NOT_ALLOWED,
  ERROR_ROUTE_NOT_SEARCHABLE,
  ERROR_VALUE_EXISTS,
  ERROR_WO_NOT_FOUND,
  ERROR_ZIP_CODE_TIMEZONE_INVALID,
} from '../../../constants/Common/errorMessage';
import ERROR_MESSAGE_PAIR from '../../../constants/Common/errorMessagePair';

/**
 * @description
 * Function that generate specific error message according to error code.
 * @param {string} code
 * Error code from API
 * @returns {string}
 * Error message
 */
export const specificCodeErrorMessageGenerator = ({
  code,
  fields,
  dependency,
}) => {
  /** Check specific code */
  if (code === 'dependent' || code === 'same_location') {
    const getFieldPair = fields?.map(field => COLUMN_ITEM_PAIR?.[field]);
    const getDependencyPair = dependency?.map(
      field => COLUMN_ITEM_PAIR?.[field],
    );

    return `${ERROR_MESSAGE_PAIR?.[code]} ${getFieldPair?.join(', ')}${
      getDependencyPair ? ` and ${getDependencyPair?.join(', ')}` : ''
    }`;
  }
  if (code === 'job_started') {
    return `Cannot ${fields?.[0]} assigned driver. This job is already started.`;
  }
  if (code === 'geofence_center_missing') {
    return `${fields?.[0]}'s geofence center coordinate is missing.`;
  }
  if (code === 'geofence_not_found') {
    return `${fields?.[0]}'s geofence is not found.`;
  }
  if (code === 'button_not_active') {
    return `This ${fields?.[0]} button is not activated. Please contact division manager.`;
  }
  if (code === 'main_permission_missing') {
    return ERROR_MAIN_PERMISSION_MISSING;
  }
  if (code === 'permission_missing_for_first_page') {
    return ERROR_PERMISSION_MISSING_FOR_FIRST_PAGE;
  }
  if (code === 'specific_location_required') {
    return `Location ${COLUMN_ITEM_PAIR?.[fields?.[0]]} ${
      COLUMN_ITEM_PAIR?.[fields?.[1]]
    } is required.`;
  }
  if (code === 'ap_location_assigned') {
    return `AP has been assigned to the job that includes the ${fields[0]}.`;
  }
  if (code === 'zip_code_timezone_invalid') {
    return ERROR_ZIP_CODE_TIMEZONE_INVALID;
  }
  if (code === 'converted_wo_exists') {
    return `This workorder has been converted to ${
      COLUMN_ITEM_PAIR?.[fields?.[0]]
    }. If you want to change this workorder, please delete or rollback the ${
      fields[1]
    }.`;
  }
  if (code === 'empty_schedule_datetime') {
    return `Following location's schedule datetime must have  at least one non-empty value. 
    ${COLUMN_ITEM_PAIR?.[fields?.[0]]}${
      fields?.[1] ? COLUMN_ITEM_PAIR?.[fields?.[1]] : ''
    }${fields?.[2] ? COLUMN_ITEM_PAIR?.[fields?.[2]] : ''}.`;
  }
  if (code === 'value_exists') {
    return ERROR_VALUE_EXISTS;
  }
  if (code === 'wo_not_found') {
    return ERROR_WO_NOT_FOUND;
  }
  if (code === 'complete_wo_delete_error') {
    return ERROR_COMPLETE_WO_DELETE;
  }
  if (code === 'existing_ap_ar_delete_error') {
    return ERROR_EXISTING_AP_AR_DELETE;
  }
  if (code === 'edi_204_update_error') {
    return ERROR_EDI_204_UPDATE;
  }
  if (code === 'group_has_user') {
    return ERROR_GROUP_HAS_USER;
  }
  if (code === 'permission_first_page_mismatch') {
    return ERROR_PERMISSION_FIRST_PAGE_MISMATCH;
  }
  if (code === 'not_supported_file_size') {
    return ERROR_NOT_SUPPORTED_FILE_SIZE;
  }
  if (code === 'qb_auth_code') {
    return ERROR_QB_AUTH_CODE;
  }
  if (
    [
      'qb_stale_object',
      'qb_refresh_fail',
      'qb_not_found',
      'qb_invalid',
      'qb_unknown_error',
      'qb_unauthenticated',
      'qb_already_exist',
      'qb_invoice_not_found',
      'qb_work_order_not_found',
    ].includes(code)
  ) {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'qb_timeout') {
    return ERROR_QB_TIMEOUT;
  }
  if (code === 'wo_deleted') {
    return ERROR_QB_DELETED;
  }
  if (code === 'job_not_completed') {
    return ERROR_JOB_NOT_COMPLETED;
  }
  if (code === 'dual_job_exists') {
    return `Cannot ${
      COLUMN_ITEM_PAIR?.[fields?.[0]]
    } this workorder. Dual job exists.`;
  }
  if (code === 'division_has_wo') {
    return ERROR_DIVISION_HAS_WO;
  }
  if (code === 'bill_no_creation_failed') {
    return `Please update ${COLUMN_ITEM_PAIR?.[fields?.[0]]}.`;
  }
  if (code === 'cannot_be_past') {
    return `${COLUMN_ITEM_PAIR?.[fields?.[0]]} and ${
      COLUMN_ITEM_PAIR?.[fields?.[1]]
    } cannot be set to the past`;
  }
  if (code === 'navigation_failed') {
    return ERROR_NAVIGATION_FAILED;
  }
  if (code === 'qb_locked') {
    return ERROR_QB_LOCKED;
  }
  if (code === 'address_invalid_error') {
    return ERROR_ADDRESS_INVALID;
  }
  if (code === 'invalid_location') {
    return ERROR_INVALID_LOCATION;
  }
  if (code === 'qb_non_zero_balance') {
    return ERROR_QB_NON_ZERO_BALANCE;
  }
  if (code === 'dual_job_reassign') {
    return ERROR_DUAL_JOB_REASSIGN;
  }
  if (code === 'converted_export_wo_delete') {
    return ERROR_CONVERTED_EXPORT_WO_DELETE;
  }
  if (code === 'return_location_not_found') {
    return ERROR_RETURN_LOCATION_NOT_FOUND;
  }
  if (code === 'return_schedule_update_not_allowed') {
    return ERROR_RETURN_SCHEDULE_UPDATE_NOT_ALLOWED;
  }
  if (code === 'driver_assigned') {
    return `A driver has been assigned to the ${
      COLUMN_ITEM_PAIR?.[fields?.[0]]
    }. Please unassign the driver first.`;
  }
  if (code === 'route_not_searchable') {
    return ERROR_ROUTE_NOT_SEARCHABLE;
  }
  if (code === 'get_vehicle_failed') {
    return ERROR_GET_VEHICLE_FAILED;
  }
  if (code === 'cannot_delete_location') {
    return ERROR_CANNOT_DELETE_LOCATION;
  }
  if (code === 'not_available') {
    return ERROR_NOT_AVAILABLE;
  }
  if (code === 'previous_actual_error') {
    return ERROR_PREVIOUS_ACTUAL_ERROR;
  }
  if (code === 'data_conflicted') {
    return ERROR_DATA_CONFLICTED;
  }

  const getCodeErrorMessage = ERROR_MESSAGE_PAIR?.[code];
  return getCodeErrorMessage;
};

/**
 * @description
 * Function that generate specific error message according to error fields.
 * @param {list} fields
 * List of error fields
 * @return {string}
 * Error message
 */
export const specificFieldsErrorMessageGenerator = ({ fields }) => {
  /** Check specific fields */
  /** Will be updated */
  return '';
};
