import React, { useState } from 'react';
import { MAPPING_PAGE } from '../../../../constants/Admin/DivConf/adminDivConfElements';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import StyledDivConfFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfFieldLabel';
import StyledDivConfServiceCodeField from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeField';
import StyledDivConfServiceCodeTitle from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeTitle';
import StyledDivConfServiceCodeGroupFiledWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeGroupFiledWrapper';

const APTableRateContainer = ({
  apTableRate,
  apProductCodeOption,
  div,
  postProductCodeMutation,
  patchProductCodeMutation,
  deleteProductCodeMutation,
  patchAPTAbleRateMutation,
  isLoadingAPProductCode,
  apTable,
}) => {
  const [productCode, setProductCode] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);

  const handleSubmit = ({ type, code, buttonRef }) => {
    const mappingPage = MAPPING_PAGE[type];
    if (code) {
      if (apTableRate?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: apTableRate?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      postProductCodeMutation({
        div,
        productCode: code?.value,
        mappingPage,
        buttonRef,
      });
      return;
    }
    setIsDeleted(true);
    deleteProductCodeMutation({
      seq: apTableRate?.seq,
      productCode: apTableRate?.product_code,
      buttonRef,
    });
  };

  return (
    <div className="w-[456px] h-full flex flex-col gap-y-[24px]">
      <div className="flex flex-col gap-y-[8px]">
        <StyledDivConfServiceCodeTitle>MILE</StyledDivConfServiceCodeTitle>
        <div className="w-full flex gap-x-[12px]">
          <div className="flex-1 flex flex-col gap-y-[8px]">
            <StyledDivConfFieldLabel>LESS</StyledDivConfFieldLabel>
            <StyledDivConfServiceCodeField $styles={{ mb: -17 }}>
              <CommonTextInput
                inputName="less"
                labelVisible={false}
                defaultValue={apTable?.less}
                adornment={{
                  content: '$',
                  position: 'start',
                }}
                onChangeHandler={({ e, onChange, ...handlerProps }) => {
                  if (
                    REGEX_DECIMAL_9_2.test(e?.target?.value) ||
                    e?.target?.value === ''
                  ) {
                    onChange(e?.target?.value);
                  }
                }}
                onKeyDownHandler={({ e, setDisabled }) => {
                  if (e.key === 'Enter') {
                    setDisabled(true);
                    patchAPTAbleRateMutation({
                      div,
                      distance: apTable?.distance,
                      less: e.target.value || 0,
                      more: apTable?.more,
                      setDisabled,
                    });
                  }
                }}
                onBlurHandler={({ e, setDisabled }) => {
                  if (e?.target?.value !== apTable?.less?.toString()) {
                    setDisabled(true);
                    patchAPTAbleRateMutation({
                      div,
                      distance: apTable?.distance,
                      less: e.target.value || 0,
                      more: apTable?.more,
                      setDisabled,
                    });
                  }
                }}
              />
            </StyledDivConfServiceCodeField>
          </div>
          <div className="flex-1 flex flex-col gap-y-[8px]">
            <StyledDivConfFieldLabel>MILE</StyledDivConfFieldLabel>
            <StyledDivConfServiceCodeField $styles={{ mb: -17 }}>
              <CommonTextInput
                labelVisible={false}
                inputName="distance"
                defaultValue={apTable?.distance}
                adornment={{
                  content: 'Mile',
                  position: 'end',
                }}
                onChangeHandler={({ e, onChange }) => {
                  if (REGEX_ONLY_NUMBER.test(e?.target?.value)) {
                    onChange(e?.target?.value);
                  }
                }}
                onKeyDownHandler={({ e, setDisabled }) => {
                  if (e.key === 'Enter') {
                    setDisabled(true);
                    patchAPTAbleRateMutation({
                      div,
                      distance: e.target.value || 0,
                      less: apTable?.less,
                      more: apTable?.more,
                      setDisabled,
                    });
                  }
                }}
                onBlurHandler={({ e, setDisabled }) => {
                  if (e?.target?.value !== apTable?.distance?.toString()) {
                    setDisabled(true);
                    patchAPTAbleRateMutation({
                      div,
                      distance: e.target.value || 0,
                      less: apTable?.less,
                      more: apTable?.more,
                      setDisabled,
                    });
                  }
                }}
              />
            </StyledDivConfServiceCodeField>
          </div>

          <div className="flex-1 flex flex-col gap-y-[8px]">
            <StyledDivConfFieldLabel>MORE</StyledDivConfFieldLabel>
            <StyledDivConfServiceCodeField $styles={{ mb: -17 }}>
              <CommonTextInput
                labelVisible={false}
                inputName="more"
                defaultValue={apTable?.more}
                adornment={{
                  content: '$',
                  position: 'start',
                }}
                onChangeHandler={({ e, onChange, ...handlerProps }) => {
                  if (
                    REGEX_DECIMAL_9_2.test(e?.target?.value) ||
                    e?.target?.value === ''
                  ) {
                    onChange(e?.target?.value);
                  }
                }}
                onKeyDownHandler={({ e, setDisabled }) => {
                  if (e.key === 'Enter') {
                    setDisabled(true);
                    patchAPTAbleRateMutation({
                      div,
                      distance: apTable?.distance,
                      less: apTable?.less,
                      more: e.target.value || 0,
                      setDisabled,
                    });
                  }
                }}
                onBlurHandler={({ e, setDisabled }) => {
                  if (e?.target?.value !== apTable?.more?.toString()) {
                    setDisabled(true);
                    patchAPTAbleRateMutation({
                      div,
                      distance: apTable?.distance,
                      less: apTable?.less,
                      more: e.target.value || 0,
                      setDisabled,
                    });
                  }
                }}
              />
            </StyledDivConfServiceCodeField>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-[8px]">
        <StyledDivConfServiceCodeTitle>RATE</StyledDivConfServiceCodeTitle>
        <div className="w-full flex gap-x-[12px]">
          <div className="flex-1 flex flex-col gap-y-[8px]">
            <StyledDivConfFieldLabel>AP RATE</StyledDivConfFieldLabel>
            <StyledDivConfServiceCodeGroupFiledWrapper>
              <StyledDivConfServiceCodeField $styles={{ width: 144, mb: -17 }}>
                <CommonAutoComplete
                  inputName="apTableProductCode"
                  placeholder="Service Code"
                  color="#264B9F"
                  option={apProductCodeOption}
                  defaultValue={apTableRate?.product_code}
                  onChangeHandler={({ onChange, selectProps }) => {
                    setIsDeleted(false);
                    onChange?.(selectProps?.label?.split(' / ')[0]);
                    setProductCode(selectProps);
                  }}
                  isLoading={isLoadingAPProductCode}
                  labelVisible={false}
                />
              </StyledDivConfServiceCodeField>
              <StyledDivConfServiceCodeField $styles={{ width: 204, mb: -17 }}>
                <CommonTextInput
                  inputName="apTableProductCodeName"
                  placeholder="Service Code Name"
                  defaultValue={
                    isDeleted
                      ? ''
                      : productCode?.name || apTableRate?.product_code_name
                  }
                  disabled
                  labelVisible={false}
                />
              </StyledDivConfServiceCodeField>
              <StyledDivConfServiceCodeField $styles={{ width: 84 }}>
                <CommonButton
                  category="complete"
                  preventDupClick
                  customStyle={{ height: '30px', width: '100%' }}
                  onClick={({ buttonRef }) =>
                    handleSubmit({
                      type: 'DIV_AP_TABLE',
                      code: productCode,
                      buttonRef,
                    })
                  }
                >
                  Apply
                </CommonButton>
              </StyledDivConfServiceCodeField>
            </StyledDivConfServiceCodeGroupFiledWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APTableRateContainer;
