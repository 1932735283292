import combineNumber from '../../Common/Generator/combineNumber';
import { concatPhoneNumber } from '../../../Common/Handler/phoneNumberHandler';
import {
  BOOLEAN_FIELD_LIST,
  FILE_FIELD_LIST,
  PHONE_NUMBER_FIELD_LIST,
  SSN_FIELD_LIST,
} from '../../../../constants/Master/Company/preprocessingFields';
import handleNullField from '../../Common/Handler/handleNullField';

const handleCompanyData = ({ data, isAdd }) => {
  PHONE_NUMBER_FIELD_LIST.forEach(field => {
    data[field] = concatPhoneNumber({
      country: data?.[`${field}_country`],
      phone: data?.[field],
    });
    delete data?.[`${field}_country`];

    const currentNumber = data?.[field];
    if (currentNumber?.includes('+')) {
      data[field] = currentNumber.replace(/^(.*?\+\d+\.)\1/, '$1');
    }
  });

  if (data?.ssn_1 || data?.ssn_2 || data?.ssn_3) {
    data.ssn = combineNumber(SSN_FIELD_LIST, data);
  }

  if (data?.created_by) {
    delete data?.created_by;
  }

  if (data?.updated_by) {
    delete data?.updated_by;
  }

  data.geofence = {
    place_id: data.place_id,
    latitude: data.lat,
    longitude: data.lng,
  };

  const newData = handleNullField({
    data,
    isAdd,
    fileFieldList: FILE_FIELD_LIST,
    booleanFieldList: BOOLEAN_FIELD_LIST,
  });
  return newData;
};

export default handleCompanyData;
