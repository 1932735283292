import React from 'react';
import StyledToputilityBarWrapper from '../../../styles/CustomerReport/Common/StyledTopUtilityBarWrapper';
import MainUtilityBar from './MainUtilityBar';
import SettingUtilityBar from './SettingUtilityBar';

const TopUtilityBar = ({ ...props }) => {
  /** Get props data */
  const { gridRef, subCategory } = props;

  /** Modal opener */
  const {
    setAdvancedSearchOpener,
    setSavedListOpener,
    setSaveItemOpener,
    setCustomColumnOpener,
    selectedList,
    searchParams,
    columnDefs,
    patchMainMutate,
    div,
  } = props;

  /** Delete Handler */
  const { deleteSettingMutate, confirmDeleteOptions, alertModalOptions } =
    props;

  return (
    <StyledToputilityBarWrapper>
      {subCategory === 'report-main' && (
        <MainUtilityBar
          gridRef={gridRef}
          setAdvancedSearchOpener={setAdvancedSearchOpener}
          setSavedListOpener={setSavedListOpener}
          setSaveItemOpener={setSaveItemOpener}
          setCustomColumnOpener={setCustomColumnOpener}
          selectedList={selectedList}
          searchParams={searchParams}
          columnDefs={columnDefs}
          patchMainMutate={patchMainMutate}
          div={div}
        />
      )}
      {subCategory === 'report-setting' && (
        <SettingUtilityBar
          gridRef={gridRef}
          deleteSettingMutate={deleteSettingMutate}
          confirmDeleteOptions={confirmDeleteOptions}
          alertModalOptions={alertModalOptions}
        />
      )}
    </StyledToputilityBarWrapper>
  );
};

export default TopUtilityBar;
