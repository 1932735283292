import React from 'react';
import BGColor from '../BGColor';
import StyledDivConfFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfFieldLabel';

const BGColorContainer = ({
  bgColor,
  div,
  patchBGColorActivateMutation,
  patchBGColorMutation,
  patchBGColorLBSMutation,
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
}) => {
  return (
    <div className="flex flex-col gap-y-[12px]">
      <StyledDivConfFieldLabel>BACKGROUND COLOR</StyledDivConfFieldLabel>
      <div className="flex flex-col gap-y-[12px]">
        <BGColor
          name="CF"
          inputName="CF"
          isBorder
          isActive={bgColor[0].active}
          color={bgColor[0].color}
          div={div}
          patchBGColorActivateMutation={patchBGColorActivateMutation}
          patchBGColorMutation={patchBGColorMutation}
        />
        <BGColor
          name="CFS"
          inputName="CFS"
          isBorder
          isActive={bgColor[1].active}
          color={bgColor[1].color}
          div={div}
          patchBGColorActivateMutation={patchBGColorActivateMutation}
          patchBGColorMutation={patchBGColorMutation}
        />
        <BGColor
          name="HAZMAT"
          inputName="Hazmat"
          isBorder
          isActive={bgColor[2].active}
          color={bgColor[2].color}
          div={div}
          patchBGColorActivateMutation={patchBGColorActivateMutation}
          patchBGColorMutation={patchBGColorMutation}
        />
        <BGColor
          name="HOT"
          inputName="Hot"
          isBorder
          isActive={bgColor[3].active}
          color={bgColor[3].color}
          div={div}
          patchBGColorActivateMutation={patchBGColorActivateMutation}
          patchBGColorMutation={patchBGColorMutation}
        />
        <BGColor
          name="OVERWEIGHT"
          inputName="Overweight"
          isBorder
          isActive={bgColor[4].active}
          color={bgColor[4].color}
          lbs={bgColor[4].lbs}
          div={div}
          patchBGColorActivateMutation={patchBGColorActivateMutation}
          patchBGColorMutation={patchBGColorMutation}
          patchBGColorLBSMutation={patchBGColorLBSMutation}
          setIsMessageOpen={setIsMessageOpen}
          setMessage={setMessage}
          setIsSuccess={setIsSuccess}
        />
        <BGColor
          name="OVERHEIGHT"
          inputName="Overheight"
          isBorder
          isActive={bgColor[5].active}
          color={bgColor[5].color}
          div={div}
          patchBGColorActivateMutation={patchBGColorActivateMutation}
          patchBGColorMutation={patchBGColorMutation}
        />
        <BGColor
          name="CHASSIS TW"
          inputName="Chassis TW"
          isBorder
          isActive={bgColor[6].active}
          color={bgColor[6].color}
          lbs={bgColor[6].lbs}
          div={div}
          patchBGColorActivateMutation={patchBGColorActivateMutation}
          patchBGColorMutation={patchBGColorMutation}
          patchBGColorLBSMutation={patchBGColorLBSMutation}
          setIsMessageOpen={setIsMessageOpen}
          setMessage={setMessage}
          setIsSuccess={setIsSuccess}
        />
      </div>
    </div>
  );
};

export default BGColorContainer;
