import { TOAST_TYPE } from '../../../constants/Common/Toast/toastTypes';
import { SUCCESS_COPY } from '../../../constants/Common/successMessage';

const handleCopyClipBoard = async ({ copyText, addToast }) => {
  try {
    await navigator.clipboard.writeText(copyText);
    addToast?.({ message: SUCCESS_COPY });
  } catch (err) {
    addToast?.({
      message: 'Failed to copy. Please try again.',
      type: TOAST_TYPE?.ERROR,
    });
  }
};

export default handleCopyClipBoard;
