import dayjs from 'dayjs';
import datetimeComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import generateDatetimeFilters from '../../../Common/Datagrid/Generator/generateDatetimeFilters';

const getDateTimeDef = field => {
  return {
    cellClass: 'datetime',
    comparator: datetimeComparator,
    ...generateDatetimeFilters(),
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dayjs(dateValue).isValid()
        ? dayjs(dateValue).format('MM/DD/YY HH:mm')
        : null;
    },
  };
};

export default getDateTimeDef;
