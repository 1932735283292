import styled from 'styled-components';

const StyledDivConfServiceCodeFieldLabel = styled.div`
  display: flex;
  align-items: center;
  color: #595656;
  font-size: 12px;
  font-weight: 500;
  width: ${({ $width }) => ($width ? `${$width}px` : '152px')};
  height: 16px;
`;
export default StyledDivConfServiceCodeFieldLabel;
