export const STATISTICS_HEADER_WEEKLY_VALUE = {
  Sunday: 'SUN',
  Monday: 'MON',
  Tuesday: 'TUE',
  Wednesday: 'WED',
  Thursday: 'THU',
  Friday: 'FRI',
  Saturday: 'SAT',
  Week: 'WEEK',
  Month: 'MONTH',
};

export const STATISTICS_HEADER_MONTHLY_VALUE = {
  week1: 'WEEK 1',
  week2: 'WEEK 2',
  week3: 'WEEK 3',
  week4: 'WEEK 4',
  week5: 'WEEK 5',
  total: 'TOTAL',
};

export const STATISTICS_HEADER_YEARLY_VALUE = {
  1: 'JANUARY',
  2: 'FEBRUARY',
  3: 'MARCH',
  4: 'APRIL',
  5: 'MAY',
  6: 'JUNE',
  7: 'JULY',
  8: 'AUGUST',
  9: 'SEPTEMBER',
  10: 'OCTOBER',
  11: 'NOVEMBER',
  12: 'DECEMBER',
  total: 'TOTAL',
};

export const STATISTICS_HEADER_CYCLE_VALUE = {
  weekly: STATISTICS_HEADER_WEEKLY_VALUE,
  monthly: STATISTICS_HEADER_MONTHLY_VALUE,
  yearly: STATISTICS_HEADER_YEARLY_VALUE,
};

export const STATISTICS_TABLE_TITLE_VALUE = {
  do: 'DO',
  dilv: 'DELIVERY',
  pullout: 'PULL OUT',
  'empty-load': 'EMPTY/LOAD',
  return: 'RETURN',
  invoice: 'INVOICE',
  demurrage: 'DEMURRAGE ($)',
  pierpass: 'PIERPASS ($)',
  perdiem: 'PERDIEM ($)',
};

export const STATISTICS_ROW_TITLE_VALUE = {
  total: 'TOTAL',
  import: 'IMPORT',
  export: 'EXPORT',
  van: 'VAN',
  brokerage: 'BROKERAGE',
  barechassis: 'BARE CHASSIS',
  warehouse: 'WH',
  riv_warehouse: 'RIV WH',
  empty: 'EMPTY',
  load: 'LOAD',
  cases: '',
  amount: ' ($)',
};

export const STATISTICS_CYCLE_WIDTH_VALUE = {
  weekly: '175px',
  monthly: '262px',
  yearly: '120.5px',
};

export const STATISTICS_ROW_DIVISION_COLOR_VALUE = {
  ALL: { background: '#264B9F', text: '#FFFFFF' },
  PHX: { background: '#FFE9E9', text: '#841818' },
  LA: { background: '#E7EEFF', text: '#002265' },
  MOB: { background: '#FFEADA', text: '#AF4D23' },
  SAV: { background: '#FEFDE9', text: '#8E5424' },
  HOU: { background: '#E4FFF3', text: '#427966' },
  RIV: { background: '#DAF6FF', text: '#265665' },
};
export const STATISTICS_TABLE_NORMAL_TYPE_LIST = [
  'do',
  'dilv',
  'pullout',
  'return',
  'invoice',
  'empty-load',
];

export const STATISTICS_TABLE_COST_TYPE_LIST = [
  'pierpass',
  'demurrage',
  'perdiem',
];

export const STATISTICS_TABLE_NORMAL_AND_COST_TYPE_LIST = 'invoice';

export const SORTED_DIVISION = ['PHX', 'LA', 'HOU', 'SAV', 'MOB'];

export const WAREHOUSE_DIVISION = ['PHX', 'LA'];
