import dayjs from 'dayjs';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import generateDateFilters from '../../../Common/Datagrid/Generator/generateDateFilters';

const getDateDef = field => {
  return {
    cellClass: 'date',
    comparator: dateComparator,
    ...generateDateFilters(),
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
    },
  };
};

export default getDateDef;
