import React, { useState } from 'react';
import { MAPPING_PAGE } from '../../../../constants/Admin/DivConf/adminDivConfElements';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import StyledDivConfServiceCodeWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeWrapper';
import StyledDivConfServiceCodeTitle from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeTitle';
import StyledDivConfServiceCodeContentWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeContentWrapper';
import StyledDivConfServiceCodeFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeFieldLabel';
import StyledDivConfServiceCodeField from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeField';
import StyledDivConfServiceCodeGroupFiledWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeGroupFiledWrapper';

const MobileContainer = ({
  dryRun,
  waiting,
  other,
  apProductCodeOption,
  div,
  postProductCodeMutation,
  patchProductCodeMutation,
  deleteProductCodeMutation,
  isLoadingAPProductCode,
}) => {
  const [dryRunProductCode, setTruckingChargeProductCode] = useState({});
  const [waitingProductCode, setFuelSurchargeProductCode] = useState({});
  const [otherProductCode, setChassisRateProductCode] = useState({});
  const [deletedType, setDeletedType] = useState('');

  const handleSubmit = ({ type, code, buttonRef }) => {
    const mappingPage = MAPPING_PAGE[type];
    if (code) {
      if (dryRun?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: dryRun?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      if (waiting?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: waiting?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      if (other?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: other?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      postProductCodeMutation({
        div,
        productCode: code?.value,
        mappingPage,
        buttonRef,
      });
      return;
    }
    setDeletedType(type);
    if (dryRun?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: dryRun?.seq,
        productCode: dryRun?.product_code,
        buttonRef,
      });
    }
    if (waiting?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: waiting?.seq,
        productCode: waiting?.product_code,
        buttonRef,
      });
    }
    if (other?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: other?.seq,
        productCode: other?.product_code,
        buttonRef,
      });
    }
  };

  return (
    <StyledDivConfServiceCodeWrapper>
      <StyledDivConfServiceCodeTitle>MOBILE</StyledDivConfServiceCodeTitle>
      <StyledDivConfServiceCodeContentWrapper>
        <StyledDivConfServiceCodeGroupFiledWrapper>
          <StyledDivConfServiceCodeFieldLabel>
            DRY RUN
          </StyledDivConfServiceCodeFieldLabel>
          <StyledDivConfServiceCodeField $styles={{ width: 128, mb: -17 }}>
            <CommonAutoComplete
              inputName="dryRun"
              placeholder="Service Code"
              color="#264B9F"
              option={apProductCodeOption}
              defaultValue={dryRun?.product_code}
              onChangeHandler={({ onChange, selectProps }) => {
                setDeletedType('');
                onChange?.(selectProps?.label?.split(' / ')[0]);
                setTruckingChargeProductCode(selectProps);
              }}
              isLoading={isLoadingAPProductCode}
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 208, mb: -17 }}>
            <CommonTextInput
              inputName="dryRunName"
              placeholder="Service Code Name"
              defaultValue={
                deletedType === 'DRY_RUN'
                  ? ''
                  : dryRunProductCode?.name || dryRun?.product_code_name
              }
              disabled
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 84 }}>
            <CommonButton
              category="complete"
              preventDupClick
              customStyle={{ height: '30px', width: '100%' }}
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'DRY_RUN',
                  code: dryRunProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </StyledDivConfServiceCodeField>
        </StyledDivConfServiceCodeGroupFiledWrapper>
        <StyledDivConfServiceCodeGroupFiledWrapper>
          <StyledDivConfServiceCodeFieldLabel>
            WAITING
          </StyledDivConfServiceCodeFieldLabel>
          <StyledDivConfServiceCodeField $styles={{ width: 128, mb: -17 }}>
            <CommonAutoComplete
              inputName="waiting"
              placeholder="Service Code"
              color="#264B9F"
              option={apProductCodeOption}
              defaultValue={waiting?.product_code}
              onChangeHandler={({ onChange, selectProps }) => {
                setDeletedType('');
                onChange?.(selectProps?.label?.split(' / ')[0]);
                setFuelSurchargeProductCode(selectProps);
              }}
              isLoading={isLoadingAPProductCode}
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 208, mb: -17 }}>
            <CommonTextInput
              inputName="waitingName"
              placeholder="Service Code Name"
              defaultValue={
                deletedType === 'WAITING'
                  ? ''
                  : waitingProductCode?.name || waiting?.product_code_name
              }
              disabled
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 84 }}>
            <CommonButton
              category="complete"
              preventDupClick
              customStyle={{ height: '30px', width: '100%' }}
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'WAITING',
                  code: waitingProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </StyledDivConfServiceCodeField>
        </StyledDivConfServiceCodeGroupFiledWrapper>

        <StyledDivConfServiceCodeGroupFiledWrapper>
          <StyledDivConfServiceCodeFieldLabel>
            OTHER
          </StyledDivConfServiceCodeFieldLabel>
          <StyledDivConfServiceCodeField $styles={{ width: 128, mb: -17 }}>
            <CommonAutoComplete
              inputName="other"
              placeholder="Service Code"
              color="#264B9F"
              option={apProductCodeOption}
              defaultValue={other?.product_code}
              onChangeHandler={({ onChange, selectProps }) => {
                setDeletedType('');
                onChange?.(selectProps?.label?.split(' / ')[0]);
                setChassisRateProductCode(selectProps);
              }}
              isLoading={isLoadingAPProductCode}
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 208, mb: -17 }}>
            <CommonTextInput
              inputName="otherName"
              placeholder="Service Code Name"
              defaultValue={
                deletedType === 'OTHERS'
                  ? ''
                  : otherProductCode?.name || other?.product_code_name
              }
              disabled
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 84 }}>
            <CommonButton
              category="complete"
              width="77px"
              preventDupClick
              customStyle={{ height: '30px', width: '100%' }}
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'OTHERS',
                  code: otherProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </StyledDivConfServiceCodeField>
        </StyledDivConfServiceCodeGroupFiledWrapper>
      </StyledDivConfServiceCodeContentWrapper>
    </StyledDivConfServiceCodeWrapper>
  );
};

export default MobileContainer;
