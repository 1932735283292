import React from 'react';
import ToggleWithText from '../ToggleWithText';
import StyledDivConfServiceCodeTitle from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeTitle';
import StyledDivConfServiceCodeFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeFieldLabel';

const APTableContainer = ({ apTable, div, patchAPTAbleActivateMutation }) => {
  return (
    <div className="w-[244px] h-full flex flex-col gap-y-[8px]">
      <StyledDivConfServiceCodeTitle>AP</StyledDivConfServiceCodeTitle>
      <StyledDivConfServiceCodeFieldLabel>
        CATEGORY
      </StyledDivConfServiceCodeFieldLabel>
      <div className="flex flex-col gap-y-[12px]">
        <ToggleWithText
          name="IMPORT"
          inputName="import"
          isActive={apTable.import}
          div={div}
          mutation={patchAPTAbleActivateMutation}
          isBorder
        />
        <ToggleWithText
          name="EXPORT"
          inputName="export"
          isActive={apTable.export}
          div={div}
          mutation={patchAPTAbleActivateMutation}
          isBorder
        />
        <ToggleWithText
          name="VAN"
          inputName="van"
          isActive={apTable.van}
          div={div}
          mutation={patchAPTAbleActivateMutation}
          isBorder
        />
        <ToggleWithText
          name="BROKERAGE"
          inputName="brokerage"
          isActive={apTable.brokerage}
          div={div}
          mutation={patchAPTAbleActivateMutation}
          isBorder
        />
        <ToggleWithText
          name="BARE CHASSIS"
          inputName="bare_chassis"
          isActive={apTable.bare_chassis}
          div={div}
          mutation={patchAPTAbleActivateMutation}
          isBorder
        />
      </div>
    </div>
  );
};

export default APTableContainer;
