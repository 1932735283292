import dayjs from 'dayjs';
import NoticeHeader from '../../../../components/MobileAdmin/PODCheckList/NoticeHeader';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import { CELL_TYPE_BUTTON } from '../../../../constants/Common/DataGrid/cellType';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import generateDateFilters from '../../../Common/Datagrid/Generator/generateDateFilters';

const createPODCheckListColumnDefs = ({ handleSendClick }) => [
  {
    headerName: 'WO #',
    field: 'wo_no',
    width: 100,
    editable: false,
  },
  {
    headerName: 'EQUIPMENT',
    field: 'equip_no',
    width: 90,
    editable: false,
  },
  {
    headerName: 'DRIVER',
    field: 'driver',
    width: 72,
    editable: false,
  },
  {
    headerName: 'FROM LOC',
    field: 'from_location',
    width: 80,
    editable: false,
  },
  {
    headerName: 'TO LOC',
    field: 'to_location',
    width: 72,
    editable: false,
  },
  {
    headerName: 'DELV DATE',
    field: 'actual_in_date',
    width: 85,
    cellClass: 'date',
    editable: false,
    comparator: dateComparator,
    valueGetter: param =>
      param?.data?.actual_in_date
        ? dayjs(param?.data?.actual_in_date).format('MM/DD/YY')
        : null,
    ...generateDateFilters(),
  },
  {
    editable: false,
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_BUTTON,
        content: 'Send',
        category: 'send',
        customStyle: {
          size: 'small',
        },
        onClickHandler: handleSendClick,
      }),
    floatingFilterComponent: NoticeHeader,
    sortable: false,
    resizable: false,
    suppressMovable: true,
    width: 85,
    preventClickCell: true,
  },
];

export default createPODCheckListColumnDefs;
