import React, { useState } from 'react';
import { MAPPING_PAGE } from '../../../../constants/Admin/DivConf/adminDivConfElements';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import StyledDivConfServiceCodeWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeWrapper';
import StyledDivConfServiceCodeTitle from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeTitle';
import StyledDivConfServiceCodeContentWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeContentWrapper';
import StyledDivConfServiceCodeFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeFieldLabel';
import StyledDivConfServiceCodeField from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeField';
import StyledDivConfServiceCodeGroupFiledWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeGroupFiledWrapper';

const APContainer = ({
  apRate,
  apProductCodeOption,
  div,
  postProductCodeMutation,
  patchProductCodeMutation,
  deleteProductCodeMutation,
  isLoadingAPProductCode,
}) => {
  const [productCode, setProductCode] = useState({});
  const [deletedType, setDeletedType] = useState('');

  const handleSubmit = ({ type, code, buttonRef }) => {
    const mappingPage = MAPPING_PAGE[type];
    if (code) {
      if (apRate?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: apRate?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      postProductCodeMutation({
        div,
        productCode: code?.value,
        mappingPage,
        buttonRef,
      });
      return;
    }
    setDeletedType(type);
    if (apRate?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: apRate?.seq,
        productCode: apRate?.product_code,
        buttonRef,
      });
    }
  };

  return (
    <StyledDivConfServiceCodeWrapper>
      <StyledDivConfServiceCodeTitle>AP</StyledDivConfServiceCodeTitle>
      <StyledDivConfServiceCodeContentWrapper>
        <StyledDivConfServiceCodeGroupFiledWrapper>
          <StyledDivConfServiceCodeFieldLabel>
            AP RATE
          </StyledDivConfServiceCodeFieldLabel>
          <StyledDivConfServiceCodeField $styles={{ width: 128, mb: -17 }}>
            <CommonAutoComplete
              inputName="productCode"
              placeholder="Service Code"
              color="#264B9F"
              option={apProductCodeOption}
              defaultValue={apRate?.product_code}
              labelVisible={false}
              onChangeHandler={({ onChange, selectProps }) => {
                setDeletedType('');
                onChange?.(selectProps?.label?.split(' / ')[0]);
                setProductCode(selectProps);
              }}
              isLoading={isLoadingAPProductCode}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 208, mb: -17 }}>
            <CommonTextInput
              inputName="productCodeName"
              placeholder="Service Code Name"
              defaultValue={
                deletedType === 'AP_RATE'
                  ? ''
                  : productCode?.name || apRate?.product_code_name
              }
              disabled
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 84 }}>
            <CommonButton
              category="complete"
              preventDupClick
              customStyle={{ height: '30px', width: '100%' }}
              onClick={({ buttonRef }) =>
                handleSubmit({ type: 'AP_RATE', code: productCode, buttonRef })
              }
            >
              Apply
            </CommonButton>
          </StyledDivConfServiceCodeField>
        </StyledDivConfServiceCodeGroupFiledWrapper>
      </StyledDivConfServiceCodeContentWrapper>
    </StyledDivConfServiceCodeWrapper>
  );
};

export default APContainer;
