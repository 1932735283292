import getEmailValue from '../../../Common/Datagrid/Generator/getEmailValue';
import getDateDef from '../../../Dispatch/Common/Generator/getDateDef';
import getCheckBoxDef from './getCheckBoxDef';
import getDateTimeDef from './getDateTimeDef';
import getNumberDef from './getNumberDef';
import {
  EXPORT_WORKORDER_CATEGORY_OBJECT,
  IMPORT_WORKORDER_CATEGORY_OBJECT,
} from '../../../../constants/Main/Common/mainElement';

const DATE_FIELDS = [
  'created_date',
  'eta',
  'lfd',
  'rfd',
  'ava_date',
  'st',
  'erd',
  'empty_load_cfm',
];

const DATE_TIME_FIELDS = [
  'cutoff',
  'po_sche_datetime',
  'po_actual_in_datetime',
  'po_actual_out_datetime',
  'dilv_sche_datetime',
  'dilv_actual_in_datetime',
  'dilv_actual_out_datetime',
  'pu_sche_datetime',
  'pu_actual_in_datetime',
  'pu_actual_out_datetime',
  'rtn_sche_datetime',
  'rtn_actual_in_datetime',
  'rtn_actual_out_datetime',
];

const CHECKBOX_FIELDS = [
  'wms',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'doc_pod',
  'doc_bl',
  'doc_do',
  'doc_it',
];

const NUMBER_FIELDS = [
  'ap',
  'ap_drv',
  'ap_ch',
  'ap_pe',
  'ap_de',
  'ap_dr',
  'ar',
  'trucking',
  'ar_fsc',
  'ar_ch',
  'ar_pe',
  'ar_de',
  'ar_dr',
  'qty',
  'profit',
];

const getDef = item => {
  if (DATE_FIELDS.includes(item)) {
    return getDateDef(item);
  }
  if (DATE_TIME_FIELDS.includes(item)) {
    return getDateTimeDef(item);
  }
  if (CHECKBOX_FIELDS.includes(item)) {
    return getCheckBoxDef(item);
  }
  if (NUMBER_FIELDS.includes(item)) {
    return getNumberDef(item);
  }
  if (item === 'category') {
    return {
      valueGetter: params => {
        if (
          [
            IMPORT_WORKORDER_CATEGORY_OBJECT?.REGULAR,
            EXPORT_WORKORDER_CATEGORY_OBJECT?.REGULAR,
          ].some(category => category === params.data?.category)
        )
          return 'REG';
        if (
          [
            IMPORT_WORKORDER_CATEGORY_OBJECT?.SHUTTLE,
            EXPORT_WORKORDER_CATEGORY_OBJECT?.SHUTTLE,
          ].some(category => category === params.data?.category)
        )
          return 'SHTL';
        return params.data?.category || '';
      },
    };
  }
  if (item === 'sendto') {
    return {
      valueGetter: params => {
        return getEmailValue({ params });
      },
    };
  }
  if (item === 'wgt') {
    return {
      ...getNumberDef(item),
      valueGetter: params => {
        return Math.ceil(params.data?.wgt) || '';
      },
    };
  }
  return {};
};

export default getDef;
