import handleQueryError from '../../../utils/Common/Handler/handleQueryError';
import cusReportDefaultUrl from '../api';

const patchMain = async ({ data }) => {
  try {
    const response = await cusReportDefaultUrl.patch(
      `main/${data?.list_name}`,
      data,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchMain;
