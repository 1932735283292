const handleCopyButtonDisabled = ({ fileURL, setIsDisabledCopy }) => {
  /** Check File URL Null */
  if (!fileURL) {
    setIsDisabledCopy(true);
    return;
  }

  /** Check Blob File Type */
  const isBlobFileURL = fileURL.startsWith('blob:');

  if (isBlobFileURL) {
    setIsDisabledCopy(true);
    return;
  }

  setIsDisabledCopy(false);
};

export default handleCopyButtonDisabled;
