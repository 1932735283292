import React from 'react';
import { bgColorOptions } from '../../../../constants/Admin/DivConf/adminDivConfElements';
import { ERROR_INVALID_9_2_DECIMAL } from '../../../../constants/Common/errorMessage';
import { REGEX_DECIMAL_9_2 } from '../../../../constants/Common/regex';
import CommonSelect from '../../../Inputs/CommonSelect/CommonSelect';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import ToggleWithText from '../ToggleWithText';

const BGColor = ({
  name,
  inputName,
  isBorder,
  isActive,
  color,
  lbs,
  div,
  patchBGColorActivateMutation,
  patchBGColorMutation,
  patchBGColorLBSMutation,
  setIsMessageOpen,
  setMessage,
  setIsSuccess,
}) => {
  return (
    <div className="w-full flex gap-x-[12px] h-[30px]">
      <div className="min-w-[276px] w-[276px]">
        <ToggleWithText
          name={name}
          inputName={inputName}
          isBorder={isBorder}
          isActive={isActive}
          div={div}
          mutation={patchBGColorActivateMutation}
        />
      </div>

      <div className="w-[276px]">
        <CommonSelect
          labelVisible={false}
          inputName={`${inputName}Color`}
          defaultValue={color}
          option={bgColorOptions}
          backgroundColor={color}
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
            patchBGColorMutation({
              div,
              type: inputName,
              color: e?.target?.value,
            });
          }}
        />
      </div>
      {(name === 'OVERWEIGHT' || name === 'CHASSIS TW') && (
        <div className="w-[100px]">
          <CommonTextInput
            inputName={`${inputName}LBS`}
            adornment={{
              content: 'LBS',
              position: 'end',
            }}
            defaultValue={lbs}
            labelVisible={false}
            onChangeHandler={({ e, onChange }) => {
              if (
                REGEX_DECIMAL_9_2.test(e?.target?.value) ||
                e?.target?.value === ''
              ) {
                onChange(e?.target?.value);
              }
            }}
            onKeyDownHandler={({ e, setDisabled }) => {
              if (e.key === 'Enter') {
                setDisabled(true);
                if (!REGEX_DECIMAL_9_2.test(e?.target?.value)) {
                  setIsMessageOpen(true);
                  setMessage(ERROR_INVALID_9_2_DECIMAL);
                  setIsSuccess(false);
                  return;
                }
                patchBGColorLBSMutation({
                  div,
                  type: inputName,
                  lbs: e?.target?.value,
                  setDisabled,
                });
              }
            }}
            onBlurHandler={({ e, setDisabled }) => {
              if (!REGEX_DECIMAL_9_2.test(e?.target?.value)) {
                setIsMessageOpen(true);
                setMessage(ERROR_INVALID_9_2_DECIMAL);
                setIsSuccess(false);
                return;
              }
              if (e?.target?.value !== lbs?.toString()) {
                setDisabled(true);
                patchBGColorLBSMutation({
                  div,
                  type: inputName,
                  lbs: e?.target?.value,
                  setDisabled,
                });
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BGColor;
