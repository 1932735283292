import React from 'react';
import dayjs from 'dayjs';
import CommonDate from '../../../../../Inputs/CommonDate/CommonDate';
import CommonButton from '../../../../../CommonButton/CommonButton';
import handleDateChange from '../../../../../../utils/BCDetail/DetailInfo/Schedule/Handler/handleDateChange';
import handleDateKeyUpCapture from '../../../../../../utils/BCDetail/Common/Handler/handleDateKeyUpCapture';

const ScheduleTitle = ({
  trackingInfo,
  detailInfoData,
  setTrackingModalOpener,
  isEditable,
  ...props
}) => {
  /** Get data from detail-info dataset */
  const { empty_load_cfm: emptyLoadCFM } = detailInfoData;
  /** Const WO type */
  const woType =
    document.URL.split('/')?.[document?.URL?.split('/')?.length - 2];

  return (
    <div className="flex justify-between">
      <div className="text-[15px] font-[700] h-[18px]">
        <p>SCHEDULE</p>
      </div>
      <div className="flex items-center h-[24px] space-x-[20px]">
        <div className="flex items-center space-x-[10px]">
          <div>
            <p className="text-[11px] font-[500] text-[#383535]">
              {woType === 'export' ? 'LOAD DATE' : 'EMPTY DATE'}
            </p>
          </div>
          <div className="w-[117px]">
            <CommonDate
              inputName="empty_load_cfm"
              onChangeHandler={handleDateChange}
              onKeyUpCaptureHandler={handleDateKeyUpCapture}
              defaultValue={
                emptyLoadCFM && dayjs(emptyLoadCFM)?.isValid()
                  ? dayjs(emptyLoadCFM)
                  : null
              }
              disabled={!isEditable || !!detailInfoData?.completed}
            />
          </div>
        </div>
        <div className="mb-1">
          <CommonButton
            category="tracking"
            onClick={() => {
              setTrackingModalOpener(true);
            }}
            disabled={
              !detailInfoData?.div?.tracking_func ||
              !trackingInfo?.tracking_id ||
              !!detailInfoData?.completed
            }
          >
            Tracking
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTitle;
