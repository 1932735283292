import { Box, Link } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  PERM_TMS_REPORT_EARLY_WARNING_EDIT,
  PERM_TMS_REPORT_EARLY_WARNING_VIEW,
} from '../../constants/Common/permission';
import {
  DOWN_ARROW,
  HAMBUGER_ICON,
  PATH_COMPANY_LOGO_V2,
} from '../../constants/Order/Common/path';
import useLogoutMutation from '../../hooks/SignIn/useLogoutMutation';
import { saveNowDiv } from '../../redux/features/NowDiv';
import AlertMessage from '../SignIn/AlertMessage';
import HeaderEarlyWarning from '../TmsReport/EarlyWarning/HeaderEarlyWarning';
import Navigator from './Navigator';

const HeaderBox = styled(Box)`
  position: fixed;
  background-color: #001e5f;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  z-index: 1201;
  align-items: center;
  align-content: center;
  top: 0;
  left: 0;
`;

const Header = ({
  tabsData,
  isMenuOpen,
  handleMenu,
  handleModal,
  isModalOpen,
  setModalOpen,
  setIsSidebarOpen,
  suppressSidebar,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isMessageOpen, setIsMessageOpen] = useState(false);

  const logoutMutation = useLogoutMutation({
    setIsMessageOpen,
    setMessage,
    isLogout: true,
  });
  const onClickLogOut = async () => {
    logoutMutation.mutate();
  };

  const location = useLocation();
  const userId = localStorage.getItem('userId');
  const defaultDiv = localStorage.getItem('defaultDiv');
  const [divList, setDivList] = useState(
    localStorage.getItem('allDiv')?.trim()?.split(','),
  );

  const dropDownOpen = () => {
    setIsOpen(prev => !prev);
  };

  const handleChangeNowDiv = div => {
    dispatch(saveNowDiv(div));
    sessionStorage.setItem('nowDiv', div);
  };
  const [division, setDivision] = useState(
    sessionStorage.getItem('defaultDiv'),
  );

  /* Early Warning Permission */
  const permissionList = localStorage
    ?.getItem('permission')
    ?.trim()
    ?.split(',');

  const earlyWarningViewPermission = permissionList?.includes(
    PERM_TMS_REPORT_EARLY_WARNING_VIEW,
  );
  const earlyWarningEditPermission = permissionList?.includes(
    PERM_TMS_REPORT_EARLY_WARNING_EDIT,
  );

  useEffect(() => {
    if (division === '') dispatch(saveNowDiv(defaultDiv));
  }, [division]);

  useEffect(() => {
    setDivList(localStorage.getItem('allDiv')?.trim()?.split(','));
  }, [localStorage.getItem('allDiv')]);

  useEffect(() => {
    if (sessionStorage.getItem('nowDiv'))
      setDivision(sessionStorage.getItem('nowDiv'));
    else if (defaultDiv) {
      sessionStorage.setItem('nowDiv', defaultDiv);
      setDivision(defaultDiv);
    }
  }, [sessionStorage.getItem('nowDiv')]);

  const dropRef = useRef();

  useEffect(() => {
    const clickOutside = e => {
      if (isOpen && dropRef.current && !dropRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <AlertMessage
        isOpen={isMessageOpen}
        setIsOpen={setIsMessageOpen}
        message={message}
      />
      <HeaderBox>
        <div className="flex items-center">
          {!suppressSidebar && (
            <div
              className="cursor-pointer flex justify-center hover:bg-[#556DA1] hover:rounded-md w-[67px]"
              onClick={handleMenu}
            >
              <img src={HAMBUGER_ICON} alt="hambugerIcon" />
            </div>
          )}
          <div className="h-full w-fit min-w-fit flex justify-center items-center">
            {/* <Link href="/main/imp"> */}
            <Link href="/order/impreg">
              <img src={PATH_COMPANY_LOGO_V2} alt="companyLogo" />
            </Link>
            <div className="text-[#fff] font-bold text-[12px]">TMS</div>
          </div>
        </div>

        <div ref={dropRef} className="flex justify-evenly font-bold mr-10">
          {localStorage.getItem('userId') ? (
            <div className="flex items-center space-x-4">
              <Navigator />

              {(earlyWarningViewPermission || earlyWarningEditPermission) && (
                <HeaderEarlyWarning
                  division={division}
                  isModalOpen={isModalOpen}
                  setModalOpen={setModalOpen}
                  handleModal={handleModal}
                  earlyWarningEditPermission={earlyWarningEditPermission}
                />
              )}

              <div className="flex text-[#fff] text-base">
                <span className="text-[#B2BDC9] mr-2 text-base">Hi,</span>
                {userId}
              </div>

              <div
                onClick={dropDownOpen}
                className={`cursor-pointer w-[90px] flex items-center justify-evenly h-7 rounded-t text-[#001E5F] bg-white text-sm mr-12 ${
                  isOpen ? null : 'rounded-b'
                } `}
              >
                <p className="ml-1.5 w-7">{division}</p>
                <img src={DOWN_ARROW} alt="downArrow" />
              </div>
              <div
                className={`cursor-pointer absolute top-[2.59em] right-[9.125em] ${
                  isOpen ? null : 'hidden'
                }`}
              >
                {divList?.map((res, index) => {
                  return (
                    <div
                      onClick={() => {
                        setIsOpen(false);
                        handleChangeNowDiv(res);
                      }}
                      className={`last:rounded-b text-sm z-20 flex items-center justify-evenly py-1 last:pb-2 bg-white w-[90px] hover:bg-[#F2F4F8] ${
                        division === res
                          ? ' bg-[#F2F4F8] text-[#001E5F] z-30 '
                          : ' text-[#B2BDC9] '
                      } `}
                      key={res}
                    >
                      <div
                        className={` bg-[#001E5F] h-7 w-1 absolute left-0 ${
                          division === res ? null : 'hidden'
                        } `}
                      />
                      <p className="ml-1.5">{res}</p>
                      <div className="invisible">
                        <img src={DOWN_ARROW} alt="downArrow" />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className="w-[90px] flex rounded items-center justify-center h-7 text-white bg-[#556DA1] text-sm mr-12"
                onClick={onClickLogOut}
                style={{ cursor: 'pointer' }}
              >
                Log Out
              </div>
            </div>
          ) : undefined}
        </div>
      </HeaderBox>
    </>
  );
};

export default Header;
