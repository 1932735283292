import styled from 'styled-components';

const StyledDivConfFieldLabel = styled.div`
  font-size: 12px;
  color: #595656;
  font-weight: 500;
  height: 16px;
  display: flex;
  align-items: center;
`;

export default StyledDivConfFieldLabel;
