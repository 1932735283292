import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import dailyReportKeys from '../keys';
import getAPInput from '../../../services/DailyReport/Get/getAPinput';

const useGetAPInput = ({
  page,
  woNo,
  scheduleSeq,
  driver,
  setIsErrorModalOpen,
  setErrorMessage,
  setIsLoadingOpen,
  setIsDriverModalOpen,
}) => {
  const [apInputData, setAPInputData] = useState(null);

  const query = useQuery({
    queryKey: dailyReportKeys.driverAP({ woNo, scheduleSeq, driver, page }),
    queryFn: () => getAPInput({ woNo, scheduleSeq, driver, page }),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    const getAPInputData = query?.data?.data;
    if (query?.data?.data) {
      setAPInputData(getAPInputData);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError && query?.isFetchedAfterMount) {
      setErrorMessage(query?.error?.message);
      setIsErrorModalOpen(true);

      // Force close driver modal because of error message when schedule date time not exist
      setIsDriverModalOpen?.(prev => false);
    }
  }, [query?.isError]);

  useEffect(() => {
    if (query?.isFetching) {
      setIsLoadingOpen(true);
    } else {
      setIsLoadingOpen(false);
    }
  }, [query?.isFetching]);

  return { apInputData, ...query };
};

export default useGetAPInput;
