import { useMemo, useState } from 'react';
import {
  GRID_PINNED_ROW_HEIGHT,
  GRID_ROW_HEIGHT,
  GRID_FLOATING_FILTER_HEIGHT,
} from '../../../../constants/Common/DataGrid/setting';
import onGetRowHeight from '../Handler/onGetRowHeight';
import onModificationCellRefreshHandler from '../Handler/onModificationCellRefreshHandler';
import generateExcelExportParams from './generateExcelExportParams';

/**
 * @description
 * Generator for grid options.
 * @param {object} gridColumnDefs
 * Column definitions of the grid.
 * @param {boolean} suppressDragLeaveHidesColumns
 * If true, when you drag a column out of the grid (e.g. to the group zone) the column is not hidden.
 * @param {string} rowSelection
 * Type of Row Selection.
 * @returns {object}
 * Grid options for the data grid.
 */
const gridOptionsGenerator = ({ ...props }) => {
  const {
    gridColumnDefs,
    onRowEditingStart,
    onRowEditingStop,
    onSaveClicked,
    onFilterChange,
    onSortChange,
    suppressDragLeaveHidesColumns,
    rowSelection,
    rowHeight,
    getContextMenuItems,
  } = props;

  const gridOption = {
    // If enabled, makes it easier to set up custom components.
    reactiveCustomComponents: true,
    // If true, when you drag a column out of the grid (e.g. to the group zone) the column is not hidden.
    suppressDragLeaveHidesColumns: suppressDragLeaveHidesColumns || true,
    // Set to 'single' or 'multiple' to enable selection of rows.
    rowSelection: rowSelection || 'multiple',
    // Enable multiple cell selection by dragging.
    enableRangeSelection: true,
    // Set header height.
    headerHeight: 30,
    // Set floating filter height.
    floatingFiltersHeight: GRID_FLOATING_FILTER_HEIGHT,
    // Set row height. Default 30.
    rowHeight: rowHeight || GRID_ROW_HEIGHT,
    // To resolve chrome's resize observer issue.
    suppressBrowserResizeObserver: true,
    // To enable full row editing, set the grid option editType = 'fullRow'
    editType: 'fullRow',
    // Did not stop editing when cells lose focus.
    stopEditingWhenCellsLoseFocus: false,
    // Did not stop editing when grid lose focus.
    stopEditingWhenGridLosesFocus: false,
    // To suppress click to edit.
    // suppressClickEdit: true,
    // To suppress row click selection.
    suppressRowClickSelection: true,
    // If user defined context menu is not exist, right click is disabled.
    suppressContextMenu: !getContextMenuItems,
    // To prevent default context menu.
    preventDefaultOnContextMenu: true,
    // To enable context menu \w user defined context menu item.
    getContextMenuItems,
    // Set expanded row detail height as auto
    detailRowAutoHeight: true,
    // Turn off column virtualisation.
    suppressColumnVirtualisation: true,
    // Disable clipboard paste. But, you can use paste event with Ctrl + V.
    suppressClipboardPaste: true,
    // Set row height dynamically. If row is pinned, set row height to GRID_PINNED_ROW_HEIGHT.
    getRowHeight: param => onGetRowHeight({ gridParam: param }),
    // Triggered when the row starts editing.
    onRowEditingStarted: param => {
      onRowEditingStart?.({ gridParam: param });
      onModificationCellRefreshHandler({ gridParam: param });
    },
    // Triggered when the row stops editing.
    onRowEditingStopped: param => {
      onRowEditingStop?.({ gridParam: param });
      onModificationCellRefreshHandler({ gridParam: param });
    },
    // Triggered when the row value changed. When stop editing row and row value changed, this event will be triggered.
    onRowValueChanged: param => {
      onSaveClicked({ gridParam: param });
    },
    // Triggered when the data is filtered.
    onFilterChanged: param => {
      param.api.refreshCells();
      onFilterChange?.({ gridParam: param });
    },
    // Triggered when the data is sorted.
    onSortChanged: param => {
      param.api.refreshCells();
      onSortChange?.({ gridParam: param });
    },
    // Showing Collapsed columns in excel column
    defaultExcelExportParams: generateExcelExportParams(),
    // Set grid column definitions.
    ...gridColumnDefs,
    // Set the remaining grid options.
    ...props,
  };

  return gridOption;
};

export default gridOptionsGenerator;
