export const PHONE_NUMBER_FIELD_LIST = [
  'cell_no',
  'office_no',
  'fax',
  'acct_cell_no',
  'acct_office_no',
  'acct_fax',
];

export const SSN_FIELD_LIST = ['ssn_1', 'ssn_2', 'ssn_3'];

export const FILE_FIELD_LIST = [
  'credit_app_file',
  'w9_file',
  'ssn_file',
  'bank_file',
  'void_check',
];

export const BOOLEAN_FIELD_LIST = [
  'factoring',
  'vendor_1099',
  'active',
  'credit_hold',
  'auto_tracking',
];
