import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useGetCompanyIDList from '../../../../../../../hooks/Common/useGetCompanyIDList';
import CommonModalAutoComplete from '../../../../../../ModalInputs/CommonModalAutoComplete/CommonModalAutoComplete';
import CommonModalDate from '../../../../../../ModalInputs/CommonModalDate';
import CommonModalTextInput from '../../../../../../ModalInputs/CommonModalTextInput';
import CommonModalTime from '../../../../../../ModalInputs/CommonModalTime';
import handleDateKeyUpCapture from '../../../../../../../utils/BCDetail/Common/Handler/handleDateKeyUpCapture';

const ScheduleAddModalContentRow = ({ ...props }) => {
  const woType =
    document.URL?.split('/')?.[document?.URL?.split('/')?.length - 2];

  /** Get RHF utils */
  const { setValue } = useFormContext();

  /** Get data from props  */
  const { locationOption, selectedRowData, isCompanyIDListLoading } = props;

  /** Get company info */
  const selectedCompany = useWatch({ name: 'add.company' });
  const getCompanyInfo = useGetCompanyIDList({ company: selectedCompany });
  useEffect(() => {
    /** Set city, state, timezone according to selected location(company) */
    setValue('add.city', getCompanyInfo?.companyIDList?.[0]?.city);
    setValue('add.state', getCompanyInfo?.companyIDList?.[0]?.state);
    setValue('add.timezone', getCompanyInfo?.companyIDList?.[0]?.timezone);
  }, [getCompanyInfo.companyIDList]);

  /** Set type, ordering */
  useEffect(() => {
    if (!selectedRowData) {
      setValue('add.type', 'P/O');
      setValue('add.ordering', 0);
    } else if (selectedRowData?.type === 'DILV' && woType === 'van') {
      setValue('add.type', 'RTN');
      setValue('add.ordering', 0);
    } else {
      setValue('add.type', selectedRowData?.type);
      setValue('add.ordering', selectedRowData?.ordering + 1);
    }
  }, []);

  return (
    <div className="w-full h-[31px] flex justify-center items-center flex-row">
      <div
        className="w-[120px] h-full flex justify-center items-center flex-auto
          border-solid border-r-[1px] border-[#EEE]"
      >
        <CommonModalAutoComplete
          inputName="add.company"
          textCenter
          option={locationOption}
          onChangeHandler={({ e, selectProps, onChange }) => {
            onChange?.(selectProps?.value);
            if (!selectProps?.value) {
              setValue('add.city', '');
              setValue('add.state', '');
              setValue('add.timezone', '');
            }
          }}
          isLoading={isCompanyIDListLoading}
        />
      </div>
      <div
        className="w-[95px] h-full flex justify-center items-center flex-auto
          border-solid border-r-[1px] border-[#EEE]"
      >
        <CommonModalTextInput inputName="add.city" textCenter disabled />
      </div>
      <div
        className="w-[58px] h-full flex justify-center items-center flex-auto
          border-solid border-r-[1px] border-[#EEE]"
      >
        <CommonModalTextInput inputName="add.state" textCenter disabled />
      </div>
      <div
        className="w-[85px] h-full flex justify-center items-center flex-auto
          border-solid border-r-[1px] border-[#EEE]"
      >
        <CommonModalTextInput inputName="add.timezone" textCenter disabled />
      </div>
      <div className="w-[130px] h-full flex justify-center items-center flex-auto border-solid border-r-[1px] border-[#EEE]">
        <CommonModalDate
          inputName="add.sche_date"
          textCenter
          onChangeHandler={({ e, onChange }) => {
            if (dayjs(e).isValid()) onChange?.(e);
            else onChange?.(null);
          }}
          onKeyUpCaptureHandler={handleDateKeyUpCapture}
        />
      </div>
      <div className="w-[130px] h-full flex justify-center items-center flex-auto">
        <CommonModalTime
          inputName="add.sche_time"
          textCenter
          onChangeHandler={({ e, onChange }) => {
            if (dayjs(e).isValid()) onChange?.(e);
            else onChange?.(null);
          }}
        />
      </div>
    </div>
  );
};

export default ScheduleAddModalContentRow;
