import dayjs from 'dayjs';
import FloatingFilterSelect from '../../../../components/CommonDataGrid/Component/FloatingFilterRenderer/FloatingFilterSelect/index';
import { STATUS_SELECT_OPTION } from '../../../../constants/Admin/User/options';
import {
  STATUS_CODE,
  STATUS_VALUE,
} from '../../../../constants/Admin/User/standardCodes';
import { FIRST_PAGE_VALUE } from '../../../../constants/Admin/User/staticNames';
import { CELL_TYPE_SWITCH } from '../../../../constants/Common/DataGrid/cellType';
import datetimeComparator from '../../../Common/Datagrid/Comparator/datetimeComparator';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import {
  generateListToOption,
  generateObjectToOption,
} from '../../Common/floatingFilterSelectOptionGenerator';
import generateDatetimeFilters from '../../../Common/Datagrid/Generator/generateDatetimeFilters';

const createUserColumnDefs = ({
  disabledRef,
  userStatusPatchMutator,
  divList,
}) => {
  return [
    {
      headerName: 'DIV',
      width: 38,
      field: 'div',
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateListToOption(divList?.map(div => div?.div)),
      },
      valueGetter: gridParam => {
        const value = gridParam?.data?.userdivision_set?.default_div;
        return value;
      },
    },
    {
      headerName: 'AUTH',
      width: 130,
      field: 'group_name',
      editable: false,
    },
    {
      headerName: 'FIRST PAGE',
      width: 86,
      field: 'first_page',
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: generateObjectToOption(FIRST_PAGE_VALUE),
      },
      valueGetter: gridParam => {
        const value = FIRST_PAGE_VALUE[gridParam?.data?.first_page] || '';
        return value;
      },
    },
    { headerName: 'USER ID', width: 72, field: 'username', editable: false },
    {
      headerName: 'FIRST NAME',
      width: 92,
      field: 'first_name',
      editable: false,
    },
    {
      headerName: 'LAST NAME',
      width: 88,
      field: 'last_name',
      editable: false,
    },
    { headerName: 'EMAIL', width: 200, field: 'email', editable: false },
    {
      headerName: 'LAST SIGN IN',
      width: 97,
      field: 'last_login',
      cellClass: 'datetime',
      editable: false,
      comparator: datetimeComparator,
      ...generateDatetimeFilters(),
      valueGetter: gridParam => {
        const value =
          gridParam?.data?.last_login &&
          dayjs(gridParam?.data?.last_login)?.isValid()
            ? dayjs(gridParam?.data?.last_login)?.format?.('MM/DD/YY HH:mm')
            : '';
        return value;
      },
    },
    {
      headerName: 'STATUS',
      width: 78,
      field: 'tms_status',
      editable: false,
      clickable: true,
      cellRendererSelector: gridParam => {
        return gridParam?.value !== 'WAITING'
          ? cellRendererGenerator({
              gridParam,
              cellType: CELL_TYPE_SWITCH,
              value: gridParam?.data?.tms_is_active,
              onChangeHandler: ({ ...props }) => {
                if (
                  disabledRef?.current?.disabled &&
                  disabledRef?.current?.disabledRow === gridParam?.rowIndex
                ) {
                  return;
                }
                disabledRef.current.disabled = true;
                disabledRef.current.disabledRow = gridParam?.rowIndex;

                const { e, setChecked } = props;
                setChecked(!!e?.target?.checked);
                userStatusPatchMutator({ id: gridParam?.data?.id, setChecked });
              },
            })
          : null;
      },
      valueGetter: params => {
        if (params?.data?.tms_status === STATUS_VALUE?.WORKING) {
          return params?.data?.tms_is_active ? 'ACTIVATE' : 'INACTIVATE';
        }
        return STATUS_CODE?.[params?.data?.tms_status]?.label;
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: STATUS_SELECT_OPTION,
      },
    },
  ];
};

export default createUserColumnDefs;
