import { PREVIEW_STATUS } from '../../../../constants/BCDetail/Document';
import {
  generateTimestamp,
  generatePreviewURL,
} from '../../../Common/Generator/previewFileURLGenerator';

const handleDocumentChange = ({
  documentList,
  selectedDocType,
  watchFileList,
  fileVersions,
}) => {
  /** Check Selected Document Type */
  if (!selectedDocType) {
    return { fileURL: '', status: PREVIEW_STATUS?.NO_SELECTED_DOC };
  }

  /** Check Change File Field */
  const watchSelectedDocInfo = watchFileList?.find(file =>
    Object?.keys?.(file)?.includes(selectedDocType),
  );

  const watchSelectedDoc = watchSelectedDocInfo?.[selectedDocType];
  const watchSelectedDocName =
    watchSelectedDocInfo?.[`${selectedDocType}_file_name`];

  const isUploadNewFile = watchSelectedDoc && watchSelectedDoc instanceof File;

  if (isUploadNewFile) {
    const newFileURL = URL.createObjectURL(watchSelectedDoc);

    const isPDF = watchSelectedDocName?.toLowerCase()?.endsWith('.pdf');

    if (!isPDF) {
      return { fileURL: newFileURL, status: PREVIEW_STATUS?.NO_PDF_FILE };
    }

    return { fileURL: newFileURL, status: PREVIEW_STATUS?.AVAILABLE_PREVIEW };
  }

  const uploadedDocURL = documentList?.find(
    doc => doc?.type === selectedDocType,
  )?.file_url;

  /** Check Remove File */
  const isRemoveFile = uploadedDocURL && !watchSelectedDocName;

  if (isRemoveFile) {
    return { fileURL: '', status: PREVIEW_STATUS?.NO_UPLOAD_DOC };
  }

  /** Check Uploaded File */
  if (!uploadedDocURL) {
    return { fileURL: '', status: PREVIEW_STATUS?.NO_UPLOAD_DOC };
  }

  const currentTimeStamp = generateTimestamp();
  const versionDocURL = generatePreviewURL({
    fileURL: uploadedDocURL,
    timeStamp: fileVersions?.[selectedDocType] || currentTimeStamp,
  });

  const isPDFUploadedFile = uploadedDocURL?.toLowerCase()?.endsWith('.pdf');

  if (!isPDFUploadedFile) {
    return { fileURL: versionDocURL, status: PREVIEW_STATUS?.NO_PDF_FILE };
  }

  return { fileURL: versionDocURL, status: PREVIEW_STATUS?.AVAILABLE_PREVIEW };
};

export default handleDocumentChange;
