import dayjs from 'dayjs';
import { CELL_TYPE_DATETIME } from '../../../../constants/Common/DataGrid/cellType';
import cellRendererGenerator from '../../../Common/Datagrid/Generator/cellRendererGenerator';
import cellEditorGenerator from '../../../Common/Datagrid/Generator/cellEditorGenerator';
import {
  getDateFontColor,
  getDateFontWeight,
} from '../../../TmsReport/Common/dateStyleGenerator';
import datetimeComparator from '../../../Common/Datagrid/Comparator/datetimeComparator';
import generateDatetimeFilters from '../../../Common/Datagrid/Generator/generateDatetimeFilters';

const getEditableDatetimeDef = ({ field }) => {
  return {
    cellClass: 'datetime',
    comparator: datetimeComparator,
    ...generateDatetimeFilters(),
    cellEditorSelector: gridParam =>
      cellEditorGenerator({
        gridParam,
        cellType: CELL_TYPE_DATETIME,
      }),
    cellRendererSelector: gridParam =>
      cellRendererGenerator({
        gridParam,
        cellType: CELL_TYPE_DATETIME,
        customStyle: {
          color: getDateFontColor({ field, data: gridParam?.data }),
          fontWeight: getDateFontWeight({ field, data: gridParam?.data }),
        },
      }),
    valueSetter: params => {
      const input = params?.newValue
        ? dayjs(params?.newValue).format('YYYY-MM-DD HH:mm:ss')
        : null;
      if (params.data?.[field] !== input) {
        params.data[field] = input;
        return true;
      }
      return false;
    },
    valueGetter: params => {
      const dateValue = params.data?.[field];
      return dateValue ? dayjs(dateValue).format('MM/DD/YY HH:mm') : null;
    },
  };
};

export default getEditableDatetimeDef;
