import React, { useState } from 'react';
import { MAPPING_PAGE } from '../../../../constants/Admin/DivConf/adminDivConfElements';
import CommonButton from '../../../CommonButton/CommonButton';
import CommonAutoComplete from '../../../Inputs/CommonAutoComplete/CommonAutoComplete';
import CommonTextInput from '../../../Inputs/CommonTextInput/CommonTextInput';
import StyledDivConfServiceCodeWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeWrapper';
import StyledDivConfServiceCodeTitle from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeTitle';
import StyledDivConfServiceCodeContentWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeContentWrapper';
import StyledDivConfServiceCodeFieldLabel from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeFieldLabel';
import StyledDivConfServiceCodeField from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeField';
import StyledDivConfServiceCodeGroupFiledWrapper from '../../../../styles/Admin/DivConf/StyledDivConfServiceCodeGroupFiledWrapper';

const ARContainer = ({
  truckingCharge,
  fuelSurcharge,
  chassisRate,
  arProductCodeOption,
  div,
  postProductCodeMutation,
  patchProductCodeMutation,
  deleteProductCodeMutation,
  isLoadingARProductCode,
}) => {
  const [truckingChargeProductCode, setTruckingChargeProductCode] = useState(
    {},
  );
  const [fuelSurchargeProductCode, setFuelSurchargeProductCode] = useState({});
  const [chassisRateProductCode, setChassisRateProductCode] = useState({});
  const [deletedType, setDeletedType] = useState('');

  const handleSubmit = ({ type, code, buttonRef }) => {
    const mappingPage = MAPPING_PAGE[type];
    if (code) {
      if (truckingCharge?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: truckingCharge?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      if (fuelSurcharge?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: fuelSurcharge?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      if (chassisRate?.mapping_page === mappingPage) {
        patchProductCodeMutation({
          seq: chassisRate?.seq,
          productCode: code?.value,
          buttonRef,
        });
        return;
      }
      postProductCodeMutation({
        div,
        productCode: code?.value,
        mappingPage,
        buttonRef,
      });
      return;
    }
    setDeletedType(type);
    if (truckingCharge?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: truckingCharge?.seq,
        productCode: truckingCharge?.product_code,
        buttonRef,
      });
    }
    if (fuelSurcharge?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: fuelSurcharge?.seq,
        productCode: fuelSurcharge?.product_code,
        buttonRef,
      });
    }
    if (chassisRate?.mapping_page === mappingPage) {
      deleteProductCodeMutation({
        seq: chassisRate?.seq,
        productCode: chassisRate?.product_code,
        buttonRef,
      });
    }
  };

  return (
    <StyledDivConfServiceCodeWrapper>
      <StyledDivConfServiceCodeTitle>AR</StyledDivConfServiceCodeTitle>
      <StyledDivConfServiceCodeContentWrapper>
        <StyledDivConfServiceCodeGroupFiledWrapper>
          <StyledDivConfServiceCodeFieldLabel>
            TRUCK CHARGE
          </StyledDivConfServiceCodeFieldLabel>
          <StyledDivConfServiceCodeField $styles={{ width: 128, mb: -17 }}>
            <CommonAutoComplete
              inputName="truckingCharge"
              placeholder="Service Code"
              color="#264B9F"
              option={arProductCodeOption}
              defaultValue={truckingCharge?.product_code}
              onChangeHandler={({ onChange, selectProps }) => {
                setDeletedType('');
                onChange?.(selectProps?.label?.split(' / ')[0]);
                setTruckingChargeProductCode(selectProps);
              }}
              isLoading={isLoadingARProductCode}
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 208, mb: -17 }}>
            <CommonTextInput
              inputName="truckingChargeName"
              placeholder="Service Code Name"
              defaultValue={
                deletedType === 'TRUCKING_AR_RATE'
                  ? ''
                  : truckingChargeProductCode?.name ||
                    truckingCharge?.product_code_name
              }
              disabled
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 84 }}>
            <CommonButton
              category="complete"
              preventDupClick
              customStyle={{ height: '30px', width: '100%' }}
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'TRUCKING_AR_RATE',
                  code: truckingChargeProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </StyledDivConfServiceCodeField>
        </StyledDivConfServiceCodeGroupFiledWrapper>
        <StyledDivConfServiceCodeGroupFiledWrapper>
          <StyledDivConfServiceCodeFieldLabel>
            FUEL SURCHARGE
          </StyledDivConfServiceCodeFieldLabel>
          <StyledDivConfServiceCodeField $styles={{ width: 128, mb: -17 }}>
            <CommonAutoComplete
              inputName="fuelSurcharge"
              placeholder="Service Code"
              color="#264B9F"
              option={arProductCodeOption}
              defaultValue={fuelSurcharge?.product_code}
              onChangeHandler={({ onChange, selectProps }) => {
                setDeletedType('');
                onChange?.(selectProps?.label?.split(' / ')[0]);
                setFuelSurchargeProductCode(selectProps);
              }}
              isLoading={isLoadingARProductCode}
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 208, mb: -17 }}>
            <CommonTextInput
              inputName="fuelSurchargeName"
              placeholder="Service Code Name"
              defaultValue={
                deletedType === 'FUEL_AR_RATE'
                  ? ''
                  : fuelSurchargeProductCode?.name ||
                    fuelSurcharge?.product_code_name
              }
              disabled
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 84 }}>
            <CommonButton
              category="complete"
              preventDupClick
              customStyle={{ height: '30px', width: '100%' }}
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'FUEL_AR_RATE',
                  code: fuelSurchargeProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </StyledDivConfServiceCodeField>
        </StyledDivConfServiceCodeGroupFiledWrapper>

        <StyledDivConfServiceCodeGroupFiledWrapper>
          <StyledDivConfServiceCodeFieldLabel>
            CHASSIS RATE
          </StyledDivConfServiceCodeFieldLabel>
          <StyledDivConfServiceCodeField $styles={{ width: 128, mb: -17 }}>
            <CommonAutoComplete
              inputName="chassisRate"
              placeholder="Service Code"
              color="#264B9F"
              option={arProductCodeOption}
              defaultValue={chassisRate?.product_code}
              onChangeHandler={({ onChange, selectProps }) => {
                setDeletedType('');
                onChange?.(selectProps?.label?.split(' / ')[0]);
                setChassisRateProductCode(selectProps);
              }}
              isLoading={isLoadingARProductCode}
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 208, mb: -17 }}>
            <CommonTextInput
              inputName="chassisRateName"
              placeholder="Service Code Name"
              defaultValue={
                deletedType === 'CHASSIS_RATE'
                  ? ''
                  : chassisRateProductCode?.name ||
                    chassisRate?.product_code_name
              }
              disabled
              labelVisible={false}
            />
          </StyledDivConfServiceCodeField>
          <StyledDivConfServiceCodeField $styles={{ width: 84 }}>
            <CommonButton
              category="complete"
              preventDupClick
              customStyle={{ height: '30px', width: '100%' }}
              onClick={({ buttonRef }) =>
                handleSubmit({
                  type: 'CHASSIS_RATE',
                  code: chassisRateProductCode,
                  buttonRef,
                })
              }
            >
              Apply
            </CommonButton>
          </StyledDivConfServiceCodeField>
        </StyledDivConfServiceCodeGroupFiledWrapper>
      </StyledDivConfServiceCodeContentWrapper>
    </StyledDivConfServiceCodeWrapper>
  );
};

export default ARContainer;
